










































































































































































































































































































































































































import Vue from 'vue';
import 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import Component from 'vue-class-component';
import VueElementLoading from "vue-element-loading";
import {Ax} from '@/utils';
import i18nService from "@/services/i18n";
import store from "@/services/store";
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import CBudgetAgreementModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal
    from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import BipCard from '@/modules/budget/bip/bip-card.vue';
import BipTest from '@/modules/budget/bip/bip-test.vue';
import BipLink from '@/modules/budget/bip/bip-link.vue';
import BipValues from '@/modules/budget/bip/bip-values.vue';
import {
    BipProjectDataClass, DataType, RowNewFinanceData,
    makeToast, years, sortByField, findItem, getRowKey, padLeadingZeros, getNumber, checkAccess,
    findBipVariantNull, findBipVariantRecNull, checkGu, checkGuReg, IFallbackFindItemResponse,
    IGu,
    IPpr,
    IPrg,
    findItemGeneric
} from './bip-types';

// декоратор @Component указывает, что класс — это компонент Vue
@Component({
    name: 'bip-form',
    components: {
        'multiselect': Multiselect,
        'c-budg-form-lst': CBudgetFormsList,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        'loading': VueElementLoading,
        BipCard,
        BipTest,
        BipLink,
        BipValues
    }
})

export default class BipForm extends Vue {
    $refs!: {
        bipCard: HTMLFormElement;
        bipList: HTMLFormElement;
        drop: HTMLFormElement;
        bcPlanYear: HTMLFormElement;
        bcRegionBudget: HTMLFormElement;
        bcDataType: HTMLFormElement;
        bcVersion: HTMLFormElement;
        bcAbp: HTMLFormElement;
        bcPrg: HTMLFormElement;
        bcPpr: HTMLFormElement;
        refAgrModal: HTMLFormElement;
        refHistModal: HTMLFormElement;
    };
    private curFormSelect = '/bip/bip-form';
    private mode_code = 'bip'

    private openFilterWindow = false;
    private backList = true;
    private showForm = false;
    private showTest = false;
    private isNotEqual = false;
    private saving = false;
    private updateMark = false;
    private host = true;
    private check = false;
    private tabIndex = 0;
    private pBar = 0;
    private loading = false;
    private mountLoad = 0;

    private selRegionBudget: any = null;
    private selDataType: any = null;
    private selVersion: any = null;
    private selAbp: any = null;
    private selPrg: any = null;
    private selPpr: any = null;
    private filterBox: any = null;
    private planPeriod: any = null;
    private bipProjectData: BipProjectDataClass | null = null;

    private gpList: Map<any, any> = new Map();
    private years = [];
    private bipTableFields = [
        {
            key: 'check',
            label: '',
            class: 'toggle-show'
        },
        {
            label: '№',
            key: 'nom'
        },
        {
            label: 'Код проекта',
            key: 'code',
            sortable: true
        },
        {
            label: 'Наименование проекта',
            key: 'nameRu',
            sortable: true
        },
        {
            label: 'Период реализации',
            key: 'period',
            sortable: true
        },
        {
            label: 'Тип объекта',
            key: 'object',
            sortable: true
        },
        {
            label: 'Населенный пункт',
            key: 'locality',
            sortable: true
        },
        {
            label: 'Источник финансирования (с разбивкой)',
            key: 'source'
        },
        {
            label: 'Общая стоимость (тыс. тенге)',
            key: 'totalCost',
            sortable: true
        },
        {
            label: 'Стоимость без ПИР и ГЭ',
            key: 'costWithout',
            sortable: true
        },
        {
            label: 'Финансирование до начала планового периода  (тыс. тенге)',
            key: 'beforePlan'
        },
        {
            label: 'Сумма на плановый период',
            key: 'years'
        },
        {
            label: 'Остаток финансирования (тыс. тенге)',
            key: 'balance',
            sortable: true
        },
        {
            label: 'Статус проекта',
            key: 'status',
            sortable: true
        },
        {
            key: 'comments',
            label: 'Комментарий'
        },
        {
            key: 'more',
            label: ''
        }
    ];
    private agrBtnLst = {back: [], forward: []};
    private findDB = 0;

    public async created() {
        this.$watch('showForm', (value) => {
            if (value) {
                this.backList = false;
                this.showTest = false;
            }
            this.filterBox = {
                planPeriod: this.planPeriod,
                dataType: this.selDataType,
                variant: this.selVersion
            }
        });
        this.$watch('showTest', (value) => {
            if (value) {
                this.backList = false;
                this.showForm = false;
            }
        });
        this.$watch('backList', (value) => {
            if (value) {
                this.showForm = false;
                this.showTest = false;
            }
        });
        this.$watch('planPeriod', () => {
            this.updateVersionByYR();
        });
        this.$watch('selRegionBudget', async () => {
            this.updateVersionByYR();
        });
        this.$watch('selDataType', () => {
            this.updateVersion();
        });
        this.$watch('selVersion', () => {
            this.selAbp = null;
            this.abpFilter = [];

            this.loadBipAgreement();
            setTimeout(() => {
                if (!this.loading && this.mountLoad > 0) {
                    this.loadBipList();
                    this.loadPrevList();
                }
            }, 1000);
        });
        this.$watch('selAbp', () => {
            this.selPrg = null;
        });
        this.$watch('selPrg', () => {
            this.selPpr = null;
        });
        this.$watch('check', (value) => {
            for (const row of this.showBipList) {
                if (checkAccess(row, 'abp_agree', 'abp_agree_recipient', this.selRegionBudget.code)) {
                    this.$set(row, 'check', value);
                }
            }
        });

        await years(this, this.years);
        await this.getLocation();
        await this.loadBudgetRegions();
        await this.loadRegions();
        await this.loadDataTypeList();
        await this.loadVersions();
        this.loadDirectionList();
        this.loadObjectList();
        this.loadStatusList();
    }

    public async mounted() {
        this.host = (window.location.host === 'nsi.csi.kz' || window.location.host === 'localhost:10000');
        // console.log('host', window.location.host, (window.location.host === 'ea.csi.kz'));
        await this.loadUserAbps();
        await this.loadUserGus();
        await this.loadOperations();
        if (!this.loading) {
            await this.loadBipList();
            this.mountLoad++;
        }

        // const urlArray = location.hash.split('?')
        // if (urlArray.length>0) {
        //     const queryString = urlArray[1];
        //     const urlParams = new URLSearchParams(queryString);
        //     const id = urlParams.get('bip_id');
        //     if (id!=null) {
        //         for (const bip of this.bipList) {
        //             if (bip.id == parseInt(id)) {
        //                 this.openBip(bip);
        //                 break;
        //             }
        //         }
        //     }
        // }
    }

    private get selectedLang() {
        return i18nService.locale
    }

    private get filterVersion(): any[] {
        let result = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
            && row.year === this.planPeriod.year
            && this.selRegionBudget !== undefined
            && row.region_code === this.selRegionBudget.code
            && row.data_type === parseInt(this.selDataType.code));
        result = result.sort(sortByField('date_ueb', 'desc'));
        return result;
    }

    get filterPrg(): IPrg[] {
        const programs: number[] = [];
        let result: IPrg[] = [];
        if (this.selAbp && this.selAbp.prgList) {
            for (const bip of this.showBipList) {
                if (bip.header.abp === this.selAbp.abp) {
                    for (const fin of bip.newFinace) {
                        programs.push(fin.prg || -1);
                    }
                }

                if (bip.header.regional?.abp === this.selAbp.abp) {
                    for (const fin of bip.newFinaceRegional) {
                        programs.push(fin.prg || -1);
                    }
                }
            }
            result = this.selAbp.prgList.filter((prg: IPrg) =>
                programs.includes(prg.prg)
            );
        }
        return result;
    }

    get filterPpr(): IPpr[] {
        const pprs: number[] = [];
        for (const bip of this.showBipList) {
            for (const fin of [...bip.newFinace, ...bip.newFinaceRegional]) {
                pprs.push(fin.ppr!);
            }
        }
        let result: IPpr[] = [];
        if (this.selPrg) {
            result =
                this.selPrg.pprList?.filter((ppr: IPpr) =>
                    pprs.includes(ppr.ppr)
                ) || [];
        }
        return result;
    }

    private get showBipList(): BipProjectDataClass[] {
        let result = this.bipList.filter(bip => !bip.isDeleted);

        if (this.selVersion) {
            result = result.filter(bip => bip.variant === this.selVersion.variant_uuid
                || bip.variant_recipient === this.selVersion.variant_uuid);
        }

        if (this.selAbp) {
            result = result.filter(row => (parseInt(row.addDatas.abp.abp) === parseInt(this.selAbp.abp)));
        }

        const prgs: any[] = [];
        if (this.selPrg) {
            for (const bip of result) {
                for (const fin of bip?.addDatas.finace) {
                    if (parseInt(this.selPrg.prg) === parseInt(fin.prg)) {
                        prgs.push(bip.id);
                    }
                }
            }
            result = result.filter(row => prgs.includes(row.id));
        }

        const pprs: any[] = [];
        if (this.selPpr) {
            for (const bip of result) {
                for (const fin of bip?.addDatas.finace) {
                    if (parseInt(this.selPpr.ppr) === parseInt(fin.ppr)) {
                        pprs.push(bip.id);
                    }
                }
            }
            result = result.filter(row => pprs.includes(row.id));
        }

        result = result.filter(bip => (this.userAbps.includes(bip?.header.abp)
            || (bip?.header.regional && this.userAbps.includes(bip?.header.regional.abp))));
        return result;
    }

    private get userUiid() {
        if (store.state.user.sub) {
            return store.state.user.sub;
        }
        return null;
    } // get global user variant id

    private get editAccess(): boolean {
        return ([2, 3].includes(this.userLevel) && this.selVersion && this.selVersion !== null && this.selVersion.attribute);
    }

    private editBip(bip: BipProjectDataClass): boolean {
        let result = false;

        if (bip && bip.addDatas.status && bip.addDatas.status.operation_code !== null && bip.addDatas.status.steps) {
            const operation_codes: any[] = [];
            for (const step of bip.addDatas.status.steps) {
                if (step.operation_code !== null) {
                    step.operation_code.forEach((oper: string) => {
                        operation_codes.push(oper);
                    });
                }
            }
            if (bip.header?.finalAbp === 1) {
                result = bip.addDatas.status.operation_code.includes('gu_edit')
                    && operation_codes.includes('abp_agree')
                    && bip.header.gu !== null
                    && (this.userGus.includes(bip.header.gu.code));
            }
            if (bip.header?.finalAbp === 0 && bip.header.regional) {
                result = bip.addDatas.status.operation_code.includes('gu_edit')
                    && operation_codes.includes('abp_agree_recipient')
                    && bip.header.regional.gu !== null
                    && (this.userGus.includes(bip.header.regional.gu.code));
            }
        }
        return (result && this.editAccess) || (bip && bip.id === 0) || (bip && !bip.addDatas.status);
    }

    private editSection4(bip: BipProjectDataClass): boolean {
        if (bip) {
            if (bip.addDatas.status
                && bip.addDatas.status.operation_code !== null
                && bip.addDatas.status.steps) {
                const operation_codes: any[] = [];
                for (const step of bip.addDatas.status.steps) {
                    if (step.operation_code !== null) {
                        step.operation_code.forEach((oper: string) => {
                            operation_codes.push(oper);
                        });
                    }
                }
                if (bip.header?.finalAbp === 0
                    && bip.header.regional
                    && !operation_codes.includes('abp_agree_recipient')
                    && operation_codes.includes('abp_agree')) {
                    return (this.userGus.includes(bip.header.gu.code)
                        && bip.addDatas.status.operation_code.includes('gu_edit'));
                } else {
                    return this.editBip(bip) || !bip.addDatas.status;
                }
            }
            return this.editBip(bip) || !bip.addDatas.status;
        }
        return false;
    }

    async addNewBip() {
        this.$root.$emit('removeClass');
        this.bipProjectData = new BipProjectDataClass();
        this.bipProjectData.header.year = this.planPeriod.year;
        this.bipProjectData.header.finalAbp = 1;
        this.showForm = true;
    }

    private addNewStatus(old: any, data: any,
                         bip_code: string,
                         abp: number, gu: string, year: number,
                         data_type: number, region: string,
                         variant: string, comment_txt: string) {
        data.bip_code = bip_code,
            data.abp = abp;
        data.gu = gu;
        data.cur_year = year;
        data.region = region;
        data.data_type = data_type;
        data.variant = variant;
        data.comment_txt = comment_txt;
        data.user_id = this.userUiid;
        return Object.assign({old: old}, data);
    }


    // кнопка действия
    private agreeList: any[] = [];

    async agreementEvent() {
        let firstStatus = null;
        let firstHeader = null;
        this.agreeList = [];
        this.agrBtnLst = {back: [], forward: []};
        for (const row of this.showBipList) {
            if (row.check && row.addDatas.status) {
                if (firstStatus === null) {
                    firstStatus = row.addDatas.status.code;
                    firstHeader = row.header.hasOwnProperty('regional');
                }
                if (firstStatus !== row.addDatas.status.code) {
                    makeToast(this, 'danger', 'Согласование', 'Выберите БИПы с идентичным статусом!');
                    return;
                }
                if (firstHeader !== row.header.hasOwnProperty('regional')) {
                    makeToast(this, 'danger', 'Согласование', 'Выберите БИПы только с наличием или без Получателя!');
                    return;
                }
                this.agreeList.push(row.addDatas.status);
            }
        }
        if (this.agreeList.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Не выбрано ни один проект!');
            return;
        }
        if (firstHeader) {
            this.operationCode.push('abp_agree_recipient');
        }
        this.operationCode = this.operationCode.filter((item) => item !== 'gu_edit');

        const params = {modeCode: this.mode_code, operationCode: this.operationCode, agrCode: firstStatus};
        let nextSteps = null;
        try {
            const response = await fetch(`/api-py/get-step-next-back/${encodeURI(JSON.stringify(params))}`);
            nextSteps = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', error.toString());
            return;
        }
        if (nextSteps.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        const back: any[] = [];
        const forward: any[] = [];
        if (firstStatus === 1) {
            for (const el of nextSteps) {
                if (el.stepType === 1) {
                    if (firstHeader) {
                        if (el.operationCode === 'abp_agree_recipient') {
                            back.push(el);
                        }
                    } else {
                        if (el.operationCode === 'abp_agree') {
                            back.push(el);
                        }
                    }
                } else {
                    if (firstHeader) {
                        if (el.operationCode === 'abp_agree_recipient') {
                            forward.push(el);
                        }
                    } else {
                        if (el.operationCode === 'abp_agree') {
                            forward.push(el);
                        }
                    }
                }
            }
        } else {
            for (const el of nextSteps) {
                if (el.stepType === 1) {
                    back.push(el);
                } else {
                    forward.push(el);
                }
            }
        }
        this.$set(this.agrBtnLst, 'back', back);
        this.$set(this.agrBtnLst, 'forward', forward);

        this.$refs.refAgrModal.showEvent();
    }

    async agrClick(data: any) {
        const newAgree = [];
        const checkList = this.showBipList.filter(row => row.check && row.addDatas.status);
        for (const bip of checkList) {
            if (bip.header?.finalAbp === 1) {
                newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                    bip.code, bip.header.abp, bip.header.gu.code,
                    this.selVersion.year, this.selVersion.data_type,
                    this.selVersion.region_code, this.selVersion.variant_uuid,
                    data.commentTxt));
            } else {
                if (checkGu(this.userGus, bip) && this.selRegionBudget.code === bip.region_budget) { // сижу под Инициатором
                    console.log('сижу под Инициатором')
                    let versions_rec = [];
                    if ((bip.variant_recipient !== null) && (bip.variant_recipient.length > 0)) {
                        versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                            && row.attribute && row.variant_uuid === bip.variant_recipient);
                        if (versions_rec.length === 0) {
                            makeToast(this, 'warning', 'Внимание',
                                'Версия бюджета (' + bip.variant_recipient + ') по Получателю не актуальна:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + bip.header.regional.dataType
                                + ', Регион бюджета - ' + bip?.district_budget);
                            return;
                        }
                    }
                    if (bip.variant_recipient === null || (bip.variant_recipient !== null && bip.variant_recipient.length === 0)) {
                        if ([3, 4].includes(parseInt(bip.header.dataType))) {
                            versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && [3, 4].includes(row.data_type)
                                && bip?.district_budget
                                && row.region_code === bip?.district_budget);
                        } else {
                            versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && row.data_type === parseInt(bip.header.dataType)
                                && bip?.district_budget
                                && row.region_code === bip?.district_budget);
                        }
                        if (versions_rec.length === 0) {
                            makeToast(this, 'danger', 'Внимание',
                                'Отсутствует актуальная версия по Получателю:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + bip.header.dataType
                                + ', Регион бюджета - ' + bip?.district_budget);
                            return;
                        } else {
                            await findBipVariantNull(this, bip, bip.variant, versions_rec[0].variant_uuid);
                            if (this.findDB > 0) {
                                this.saving = false;
                                return;
                            }
                        }
                    }
                    if ((versions_rec[0].data_type === bip.header.dataType)
                        || (versions_rec[0].data_type === 4 && bip.header.dataType === 3)) {
                        this.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
                    }
                    this.$set(bip.header, 'dataType', this.selVersion.data_type);
                    this.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
                    // Статус для инициатора
                    newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                        bip.code, bip.header.abp, bip.header.gu.code,
                        this.selVersion.year, this.selVersion.data_type,
                        this.selVersion.region_code, this.selVersion.variant_uuid,
                        data.commentTxt));
                    newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                        bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                        versions_rec[0].year, versions_rec[0].data_type,
                        versions_rec[0].region_code, versions_rec[0].variant_uuid,
                        data.commentTxt));
                    this.saveForm(bip, this.selVersion.variant_uuid, versions_rec[0].variant_uuid);
                }
                if (checkGuReg(this.userGus, bip) && this.selRegionBudget.code === bip.district_budget) {
                    // сижу под Получателем
                    console.log('сижу под Получателем')
                    let versions = [];
                    if ((bip.variant !== null) && (bip.variant.length > 0)) {
                        versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                            && row.attribute && row.variant_uuid === bip.variant);
                        if (versions.length === 0) {
                            makeToast(this, 'danger', 'Внимание',
                                'Версия бюджета (' + bip.variant + ') по Инициатору не актуальна:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + bip.header.dataType
                                + ', Регион бюджета - ' + bip?.region_budget);
                            return;
                        }
                    }
                    if (bip.variant === null || (bip.variant !== null && bip.variant.length === 0)) {
                        if ([3, 4].includes(parseInt(bip.header.regional.dataType))) {
                            versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && [3, 4].includes(row.data_type)
                                && bip?.region_budget
                                && row.region_code === bip?.region_budget);
                        } else {
                            versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && row.data_type === parseInt(bip.header.regional.dataType)
                                && bip?.region_budget
                                && row.region_code === bip?.region_budget);
                        }
                        if (versions.length === 0) {
                            makeToast(this, 'warning', 'Внимание',
                                'Отсутствует актуальная версия по Инициатору:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + bip.header.regional.dataType
                                + ', Регион бюджета - ' + bip?.region_budget);
                            return;
                        } else {
                            await findBipVariantRecNull(this, bip, versions[0].variant_uuid, bip.variant_recipient);
                            if (this.findDB > 0) {
                                this.saving = false;
                                return;
                            }
                        }
                        if ((versions[0].data_type === bip.header.regional.dataType)
                            || (versions[0].data_type === 3 && bip.header.regional.dataType === 4)
                            || (versions[0].data_type === 4 && bip.header.regional.dataType === 3)) {
                            this.$set(bip.header, 'dataType', versions[0].data_type);
                        }
                    }
                    this.$set(bip.header, 'dataType', versions[0].data_type);
                    this.$set(bip.header.regional, 'dataType', this.selVersion.data_type);
                    // Статус для инициатора
                    newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                        bip.code, bip.header.abp, bip.header.gu.code,
                        versions[0].year, versions[0].data_type,
                        versions[0].region_code, versions[0].variant_uuid,
                        data.commentTxt));
                    newAgree.push(this.addNewStatus(bip.addDatas.status, data,
                        bip.code, bip.header.regional.abp, bip.header.regional.gu.code,
                        this.selVersion.year, this.selVersion.data_type,
                        this.selVersion.region_code, this.selVersion.variant_uuid,
                        data.commentTxt));
                    this.saveForm(bip, versions[0].variant_uuid, this.selVersion.variant_uuid);
                }
            }
        }
        if (this.findDB === 0) {
            this.postData('/api-py/set_bip_agreement_step', newAgree).then((response) => {
                const list = response;
                this.loadBipAgreement();
                for (const res of list) {
                    if (res.result === 'error') {
                        makeToast(this, 'danger', 'Ошибка сохранения статуса', `Ошибка ${res.errTxt}`);
                    } else {
                        makeToast(this, 'success', 'Сохранение статуса', `Успешно!`);
                    }
                }
            });
            setTimeout(() => {
                this.updateAgreement();
            }, 3000);
        }
        for (const row of this.showBipList) {
            this.$set(row, 'check', false);
        }
    }

    private checkAccess(bip: BipProjectDataClass) {
        return checkAccess(bip, 'abp_agree', 'abp_agree_recipient', this.selRegionBudget.code);
    }

    private checkBipDouble(form: BipProjectDataClass) {
        const exist = this.bipList.filter(row => row.id !== form.id
            && parseInt(row.header.year) === parseInt(form.header.year)
            && row.code === form.code
            && parseInt(row.header.dataType) === parseInt(form.header.dataType)
            && row.variant === this.selVersion.variant_uuid);

        return (exist.length > 0);
    }

    private checkFinDoubles(form: BipProjectDataClass) {
        const mapNF = new Map();
        for (const obj of form?.newFinace) {
            const count = mapNF.get(getRowKey(obj, ['abp', 'prg', 'ppr']));
            if (count === undefined) {
                mapNF.set(getRowKey(obj, ['abp', 'prg', 'ppr']), 1);
            } else {
                mapNF.set(getRowKey(obj, ['abp', 'prg', 'ppr']), count + 1);
            }
        }
        for (const nf of mapNF.values()) {
            if (nf > 1) {
                return nf;
            }
        }
        // поиск дублей по ppr и spf в newFinaceRegional
        const mapRNF = new Map();
        if (parseInt(form.header?.finalAbp) === 0
            && form?.newFinaceRegional) {
            for (const obj of form?.newFinaceRegional) {
                const count = mapRNF.get(getRowKey(obj, ['abp', 'prg', 'ppr']));
                if (count === undefined) {
                    mapRNF.set(getRowKey(obj, ['abp', 'prg', 'ppr']), 1);
                } else {
                    mapRNF.set(getRowKey(obj, ['abp', 'prg', 'ppr']), count + 1);
                }
            }
        }
        for (const nf of mapRNF.values()) {
            if (nf > 1) {
                return nf;
            }
        }
        return 0;
    } // поиск дублей по ppr и spf в newFinace

    private commentClk(agrObj: any) {
        if (agrObj !== null) {
            this.$set(agrObj, 'mode_code', 'bip');
            this.$refs.refHistModal.showEvent(agrObj);
        }
    }

    private defineBip(bipObject: BipProjectDataClass) {
        this.$set(bipObject, 'isDeleted', (!bipObject.isDeleted ? false : bipObject.isDeleted));
        this.$set(bipObject, 'openMore', false);
        this.$set(bipObject, 'check', false);
        this.$set(bipObject.header, 'editing', false);
        if (bipObject.hasOwnProperty('governmentProgram')
            && typeof (bipObject?.governmentProgram) === 'string') {
            const gpEl = this.gpList.get(bipObject?.governmentProgram);
            if (gpEl) {
                const gpList = [];
                gpList.push(gpEl);
                this.$set(bipObject, 'governmentProgram', gpList);
            }
        }

        if (!bipObject.addDatas) {
            this.$set(bipObject, 'addDatas', {});
        }
        try {
            this.$set(bipObject['addDatas'], 'abp', {abp: null, text: ''});
            this.$set(bipObject['addDatas'], 'gu', {});
            const status = this.agrMap.get(bipObject.code);
            if (status) {
                this.$set(bipObject['addDatas'], 'status', status);
            }
            this.$set(bipObject['addDatas'], 'finace', []);
            // инициатор
            if (bipObject.variant === this.selVersion.variant_uuid) {
                this.$set(bipObject['addDatas'], 'region', bipObject.region_budget);
                if (bipObject?.header?.abp) {
                    const abp = findItem(bipObject?.header?.abp, 'abp', this.abpList);
                    if (!abp.hasOwnProperty('code')) {
                        this.$set(bipObject['addDatas'], 'abp', abp);
                        this.$set(bipObject['addDatas'], 'gu', bipObject?.header?.gu.code);
                        const finace = [];
                        bipObject?.newFinace.sort(sortByField('ppr'));
                        for (const fin of bipObject?.newFinace) {

                            const newCost = {
                                'gr': fin.gr,
                                'pgr': fin.pgr,
                                'abp': bipObject?.header?.abp,
                                'prg': fin.prg,
                                'ppr': fin.ppr,
                                'spf': fin.spf
                            };
                            this.$set(newCost, 'fact1', getNumber(fin[this.planPeriod.year]));
                            this.$set(newCost, 'fact2', getNumber(fin[this.planPeriod.year + 1]));
                            this.$set(newCost, 'fact3', getNumber(fin[this.planPeriod.year + 2]));
                            // this.$set(newCost, 'ppr_cost', this.pprFinans(fin, bipObject?.period));

                            finace.push(newCost);
                        }
                        this.$set(bipObject['addDatas'], 'finace', finace);
                    }
                }
            }
            // получатель
            if (bipObject.variant_recipient === this.selVersion.variant_uuid) {
                this.$set(bipObject['addDatas'], 'region', bipObject.district_budget);
                if (bipObject?.header?.regional && bipObject?.header?.regional.abp) {
                    const abp = findItem(bipObject?.header?.regional.abp, 'abp', this.abpList);
                    if (!abp.hasOwnProperty('code')) {
                        this.$set(bipObject['addDatas'], 'abp', abp);
                        this.$set(bipObject['addDatas'], 'gu', bipObject?.header?.regional.gu.code);
                        const finace = [];
                        bipObject?.newFinaceRegional.sort(sortByField('ppr'));
                        for (const fin of bipObject?.newFinaceRegional) {

                            const newCost = {
                                'gr': fin.gr,
                                'pgr': fin.pgr,
                                'abp': bipObject?.header?.regional.abp,
                                'prg': fin.prg,
                                'ppr': fin.ppr,
                                'spf': fin.spf
                            };
                            this.$set(newCost, 'fact1', getNumber(fin[this.planPeriod.year]));
                            this.$set(newCost, 'fact2', getNumber(fin[this.planPeriod.year + 1]));
                            this.$set(newCost, 'fact3', getNumber(fin[this.planPeriod.year + 2]));
                            finace.push(newCost);
                        }
                        this.$set(bipObject['addDatas'], 'finace', finace);
                    }
                }
            }
        } catch (error) {
            console.log('err(finace) =>', bipObject?.id, error);
        }
    }

    private deleteAccess(bip: BipProjectDataClass) {
        let result = false;
        if (bip && bip.addDatas.status && bip.addDatas.status.operation_code !== null) {
            result = bip.addDatas.status.operation_code.includes('gu_edit');
        }
        if (bip && bip.header.hasOwnProperty('regional') && bip.header.regional) {
            result = result && (bip.district_budget === this.selRegionBudget.code);
        } else {
            result = result && (bip.region_budget === this.selRegionBudget.code);
        }
        return result;
    }

    private async deleteBip(item: BipProjectDataClass) {
        const versions = this.versionList.filter(row => row.variant_uuid === item.variant);
        if (versions.length > 0 && !versions[0].attribute) {
            makeToast(this, 'danger', 'Удаление', 'Удаление проекта невозможно, так как версия бюджета Инициатора не актуальна.');
            return;
        }

        if (item.header.regional && !this.userGus.includes(item.header.regional.gu.code)) {
            makeToast(this, 'danger', 'Удаление', 'Удаление проекта возможно только Получателем инвестиций');
            return;
        }

        console.log('Variant: ', item.variant);

        const response = await fetch(
            `/api-py/bip-forms/${item.code}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({variant: item.variant, variant_recipient: item.variant_recipient}),
            }
        );
        const data = await response.json();
        if (data.exists === true) {
            makeToast(
                this,
                "danger",
                "Удаление",
                "Проект удалить невозможно. Предварительно необходимо удалить поддержанную сумму со стороны УО БП"
            );
            return;
        }

        this.$bvModal.msgBoxConfirm(
            'Удаленный проект восстановлению не подлежит. Удалить проект ' + item.code + '?',
            {
                title: 'Подтверждение',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                cancelVariant: 'light',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                modalClass: 'del-item-modal-script'
            })
            .then(value => {
                if (value) {
                    const id = item.id;
                    item.isDeleted = true;
                    const newElem = {
                        form: item,
                        user_name: this.userUiid,
                        variant: item.variant,
                        variant_recipient: item.variant_recipient
                    };
                    this.postData('/api-py/save-bip-form', newElem).then((data) => {
                        if (data.result) {
                            makeToast(this, 'success', 'Удаление', 'Запись удалена');

                            for (const [bip, index] of this.bipList) {
                                if (bip.id === id) {
                                    this.bipList.splice(index, 1);
                                    break;
                                }
                            }
                        }
                    });
                }
            })
            .catch(error => {
                makeToast(this, 'danger', 'Ошибка удаления', error.toString());
            });
    }

    async downloadRep68() {
        if (this.selAbp === null || this.selPrg === null) {
            this.$bvModal
                .msgBoxOk("Необходимо выбрать АБП и Программу!", {
                    title: "Подтверждение",
                    size: "md",
                    buttonSize: "sm",
                    okVariant: "primary",
                    okTitle: "OK",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: "del-item-modal-script",
                })
                .then((value) => {
                    return;
                })
                .catch((error) => {
                    makeToast(
                        this,
                        "danger",
                        "Ошибка проверки фильтра",
                        error.toString()
                    );
                });
        } else {
            const datasMap: Map<
                number,
                {
                    object: string;
                    name: string;
                    period: string;
                    source: string | undefined;
                    totalCost: number;
                    plan_3: number;
                    coast_3: number;
                    plan_2: number;
                    coast_2: number;
                    plan_1: number;
                    coast_1: number;
                    fin: number;
                    fin_1: number;
                    fin_2: number;
                    fin_3: number;
                    note: string;
                }[]
            > = new Map();
            let gu: IGu | null = null;
            const guMap: Map<number, Map<string, any>> = new Map();
            const pprMap: Map<number, IPpr | IFallbackFindItemResponse> =
                new Map();
            for (const bip of this.showBipList) {
                try {
                    /** Сбор всевозможных подпрограмм (ppr) из newFinace/newFinaceRegional */
                    if (bip.variant === this.selVersion.variant_uuid) {
                        if (this.selAbp.abp === bip.header.abp) {
                            gu = bip.header.gu;
                        }

                        this.setMapPpr(bip.newFinace, pprMap);
                    }

                    if (
                        bip.variant_recipient === this.selVersion.variant_uuid
                    ) {
                        if (this.selAbp.abp === bip.header.regional.abp) {
                            gu = bip.header.regional.gu;
                        }

                        this.setMapPpr(bip.newFinaceRegional, pprMap);
                    }

                    for (const tab of pprMap.values()) {
                        const pprTab = tab as IPpr;

                        /** Вычислить сумму финансирование до начала планового периода (oldFinace) */
                        let coast_3 = 0;
                        let coast_2 = 0;
                        let coast_1 = 0;
                        for (const obj of bip?.oldFinace) {
                            if (obj.prg === this.selPrg.prg) {
                                if (
                                    (obj.ppr === pprTab.ppr ||
                                        (obj.ppr === null &&
                                            pprTab.ppr === -1)) &&
                                    obj.year <= this.planPeriod.year - 3
                                ) {
                                    coast_3 += getNumber(obj.totalCoast);
                                }
                                if (
                                    (obj.ppr === pprTab.ppr ||
                                        (obj.ppr === null &&
                                            pprTab.ppr === -1)) &&
                                    obj.year === this.planPeriod.year - 2
                                ) {
                                    coast_2 += getNumber(obj.totalCoast);
                                }
                                if (
                                    (obj.ppr === pprTab.ppr ||
                                        (obj.ppr === null &&
                                            pprTab.ppr === -1)) &&
                                    obj.year === this.planPeriod.year - 1
                                ) {
                                    coast_1 += getNumber(obj.totalCoast);
                                }
                            }
                        }

                        /** Вычислить сумму финансирование в период планового периода (newFinace) */
                        const finances = {
                            plan_1: 0,
                            plan_2: 0,
                            plan_3: 0,
                            fin_0: 0,
                            fin_1: 0,
                            fin_2: 0,
                            fin_3: 0,
                        };
                        if (bip.variant === this.selVersion.variant_uuid) {
                            this.setFinances(
                                bip.newFinace,
                                pprTab.ppr,
                                finances
                            );
                        }
                        if (
                            bip.variant_recipient ===
                            this.selVersion.variant_uuid
                        ) {
                            this.setFinances(
                                bip.newFinaceRegional,
                                pprTab.ppr,
                                finances
                            );
                        }

                        /** Перечень прикрепленных документов */
                        let note = "";
                        for (const f of bip.files) {
                            if (note.length === 0) {
                                note += f.text;
                            } else {
                                note += "; " + f.text;
                            }
                        }

                        const item = {
                            object: bip.object,
                            name: "",
                            period:
                                bip.period.begYear + " - " + bip.period.endYear,
                            source: pprTab.source,
                            totalCost: bip.totalCost,
                            plan_3: finances.plan_3,
                            coast_3: coast_3,
                            plan_2: finances.plan_2,
                            coast_2: coast_2,
                            plan_1: finances.plan_1,
                            coast_1: coast_1,
                            fin: finances.fin_0,
                            fin_1: finances.fin_1,
                            fin_2: finances.fin_2,
                            fin_3: finances.fin_3,
                            note: note,
                        };

                        item.name =
                            this.selectedLang === "ru"
                                ? bip.nameRu
                                : this.selectedLang == "kk"
                                    ? bip.nameKk
                                    : bip.nameRu;

                        let list = datasMap.get(pprTab.ppr);
                        if (!list) list = [];

                        let mapGu = guMap.get(pprTab.ppr);
                        if (!mapGu) mapGu = new Map();

                        if (
                            Math.round(
                                item.plan_3 +
                                item.plan_2 +
                                item.plan_1 +
                                item.coast_3 +
                                item.coast_2 +
                                item.coast_1 +
                                item.fin +
                                item.fin_1 +
                                item.fin_2 +
                                item.fin_3
                            ) > 0
                        ) {
                            list.push(item);

                            if (bip.header.regional && bip.header.regional.gu) {
                                mapGu.set(
                                    bip?.header?.regional.gu.code,
                                    bip?.header?.regional.gu
                                );
                            } else {
                                mapGu.set(
                                    bip?.header?.gu.code,
                                    bip?.header?.gu
                                );
                            }
                        }
                        datasMap.set(pprTab.ppr, list);
                        guMap.set(pprTab.ppr, mapGu);
                    }
                } catch (e) {
                    console.log(bip.id, bip.code, e.toString());
                }
            }

            /** Переносим все возможные ППР в список */
            const pprList: (IPpr | IFallbackFindItemResponse)[] = [];
            for (const ppr of pprMap.values()) {
                pprList.push(ppr);
            }

            /** Объект для передачи в отчет */
            const params: { [key: string]: any } = {
                year: this.planPeriod.year,
                dataType: null,
                selVersion: this.selVersion,
                abp: this.selAbp,
                prg: this.selPrg,
                gu: gu,
                pprList,
                lang: this.selectedLang,
            };

            params.dataType =
                this.selectedLang === "ru"
                    ? this.selDataType.name_ru
                    : this.selectedLang == "kk"
                        ? this.selDataType.name_kk
                        : this.selDataType.name_ru;

            /** Данные таблиц */
            for (const [key, value] of datasMap.entries()) {
                params[key] = value;
            }

            /** Список ГУ */
            for (const tab of guMap.keys()) {
                const listGu = [];
                const mapGu = guMap.get(tab);
                if (mapGu) {
                    for (const gu of mapGu.values()) {
                        listGu.push(gu);
                    }
                }
                params[`${tab}_gu`] = listGu;
            }

            if (pprList.length === 0) {
                makeToast(this, 'danger', 'Приложение 68', 'Не заполнена информация по финансированию планового периода (вкладка 4)');
                return;
            }

            const signParams = {
                code_modules: "004.002.007",
                abp: this.selAbp.abp,
                code_prg: this.selPrg.prg,
                code_gu: gu?.code,
                code_forms: "",
                budget_variants: this.selVersion.variant_uuid,
            };
            const signers = await this.getSigners(signParams);
            if (signers.sign_code.length > 0)
                signers.sign_code.sort(sortByField("order_num"));
            params.signers = signers.sign_code;

            if (
                signers.sign_code.length === 1 &&
                signers.sign_code[0].id_user.length == 0
            ) {
                makeToast(
                    this,
                    "warning",
                    "Внимание",
                    "Отсутствуют Подписант(ы) по БИПам"
                );
            }

            Ax(
                {
                    url: "/api-py/bip-application-68/",
                    method: "POST",
                    data: params,
                    responseType: "blob",
                },
                (data) => {
                    if (data) {
                        const url = window.URL.createObjectURL(
                            new Blob([data as BlobPart])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        if (this.selectedLang === "kk") {
                            link.setAttribute(
                                "download",
                                "68-қосымша_" +
                                this.selAbp.abp +
                                this.selPrg?.prg +
                                "_" +
                                this.selDataType.name_kk +
                                "_" +
                                this.planPeriod.year +
                                ".xls"
                            );
                        } else {
                            link.setAttribute(
                                "download",
                                "Приложение68_" +
                                this.selAbp.abp +
                                this.selPrg?.prg +
                                "_" +
                                this.selDataType.name_ru +
                                "_" +
                                this.planPeriod.year +
                                ".xls"
                            );
                        }
                        document.body.appendChild(link);
                        link.click();
                    }
                },
                (error) => {
                    makeToast(
                        this,
                        "danger",
                        "Ошибка запроса downloadRep()",
                        error.toString()
                    );
                }
            );
        }
    } // Приложение 68.xls

    private checkProjectAvailability({code, variant, variant_recipient}: {
        code: string,
        variant: string,
        variant_recipient: string
    }): boolean {
        return this.showBipList.some(bip => bip.code === code && bip.variant === variant && bip.variant_recipient === variant_recipient);
    }

    private findItem(code: number, field: string, list: any[]): any {
        return findItem(code, field, list);
    }

    getBeforePlan(bip: BipProjectDataClass) {
        return bip.header.finalAbp === 0
            ? this.getPrePeriodFunding(bip.newFinaceRegional)
            : this.getPrePeriodFunding(bip.newFinace)
    }

    private getPrePeriodFunding(arr: RowNewFinanceData[]): number {
        let result = 0;
        for (const obj of arr) {
            for (const key of Object.keys(obj)) {
                if (!isNaN(parseInt(obj[key])) && key < this.planPeriod.year) {
                    result += parseInt(obj[key]);
                }
            }
        }
        return result;
    }

    private location: any = {};

    private async getLocation() {
        const code = store.state._instanceCode;
        try {
            await fetch('/api-py/get-budget-obl/' + code)
                .then(response => response.json())
                .then(json => {
                    this.location = json;
                });
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса getLocation', error.toString());
        }
    } // запрос локации по глобальному коду области

    private getPeriodSum(bip: BipProjectDataClass) {
        let res = 0;
        const listYears = [parseInt(bip.header.year), parseInt(bip.header.year) + 1, parseInt(bip.header.year) + 2];
        if (bip.newFinace) {
            for (const d of bip.newFinace) {
                for (let i = parseInt(bip.period.begYear.toString()); i <= parseInt(bip.period.endYear.toString()); i++) {
                    if (listYears.includes(i) && d[i] != undefined && d[i] !== '') {
                        res = res + parseFloat(d[i]);
                    }
                }
            }
        }
        return res;
    }

    async getSigners(params: any) {
        try {
            const response = await fetch('/api-py/get_signatories_data_by_budget_variants/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(params)
            });
            return await response.json();
        } catch {
            makeToast(this, 'danger', 'Предупреждение', 'Ошибка запроса подписантов');
        }
    }

    private getSources(bip: BipProjectDataClass) {
        let res = '';
        const arr = [];
        if (bip.newFinace) {
            for (const d of bip.newFinace) {
                arr.push(d.ppr);
            }
        }
        arr.sort((a: any, b: any) => (a - b > 0) ? 1 : -1);
        for (const a of arr) {
            if (a !== null) {
                switch (a) {
                    case 5:
                        res = (res.length > 0 ? res += ', ВЗ' : 'ВЗ');
                        break;
                    case 11:
                        res = (res.length > 0 ? res += ', РБ' : 'РБ');
                        break;
                    case 15:
                        res = (res.length > 0 ? res += ', МБ' : 'МБ');
                        break;
                    case 32:
                        res = (res.length > 0 ? res += ', НФ' : 'НФ');
                        break;
                    case 42:
                        res = (res.length > 0 ? res += ', 042' : '042');
                        break;
                    default:
                        break;
                }
            }
        }
        return res;
    }

    private getYearsSum(bip: BipProjectDataClass) {
        let res = 0;
        if (bip.newFinace) {
            for (const fin of bip.newFinace) {
                for (const [key, value] of Object.entries(fin)) {
                    if (key.toString().length === 4) {
                        if (value && value !== null) {
                            res = res + parseFloat(value.toString());
                        }
                    }
                }
            }
        }
        return res;
    }

    private infoBip(info_id: number, file: string) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                makeToast(this, 'danger', 'Ошибка загрузки', error.toString());
            }
        );
    }

    private agrMap = new Map();

    private async loadBipAgreement(code: string = '') {
        if (this.selVersion) {
            const params = JSON.stringify({
                variant: this.selVersion.variant_uuid,
                mode_code: this.mode_code,
                operation_code: this.operationCode
            });
            try {
                this.agrMap = new Map();
                let response = await fetch(`/api-py/get-bip-agreement-by-params/${encodeURI(params)}`);
                response = await response.json();
                this.agrMap = await new Map(Object.entries(response));
                const bip = this.bipList.filter(row => row.code === code);
                if (bip.length > 0) {
                    const item = bip[0];
                    this.$set(item['addDatas'], 'status', this.agrMap.get(code));
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка get-bip-agreement', error.toString());
            }
        }
    }

    private bipList: any[] = [];

    private async loadBipList() {
        let response: any = {};
        let serverList: any[] = [];
        this.bipList = [];
        try {
            if (this.selVersion) {
                this.pBar = 0;
                this.loading = true;
                response = await fetch('/api-py/bip-forms/' + this.selVersion.variant_uuid);
                response = await response.json();
                serverList = await response.bipList;
                await this.loadFilters(serverList, new Map(Object.entries(response.abpMap)));
                this.gpList = new Map(Object.entries(response.gpMap));
                this.pBar = 10;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки serverList', error.toString());
        }

        setTimeout(() => {
            for (const bip of serverList) {
                try {
                    const bipObject = BipProjectDataClass.fromJSON(bip);
                    this.defineBip(bipObject);
                    this.bipList.push(bipObject);
                    this.pBar++;
                } catch (error) {
                    console.log(bip.code + ':' + bip.id + error.toString());
                }
            }
            this.pBar = 100;
            this.loading = false;
            this.filterUpdate();
        }, 3000);
    }

    filterUpdate() {
        const abpFilterSet = new Set();
        for (const bip of this.bipList) {
            if (bip.region_budget === this.selRegionBudget.code) {
                const abp = this.abpList.find(abp => abp.abp === bip.header.abp);
                if (abp) {
                    abpFilterSet.add(abp);
                }
            }

            if (bip.district_budget === this.selRegionBudget.code) {
                const abp = this.abpList.find(abp => bip.header.regional && abp.abp === bip.header.regional.abp);
                if (abp) {
                    abpFilterSet.add(abp);
                }
            }
        }
        this.abpFilter = Array.from(abpFilterSet);
    }

    private abpList: any[] = [];
    private abpFilter: any[] = [];

    private async loadFilters(bipList: any, abpMap: Map<any, any>) {
        if (this.userGus.length === 0) {
            await this.loadUserGus();
        }
        if (this.userAbps.length === 0) {
            await this.loadUserAbps();
        }
        this.abpList = [];
        try {
            for (const [key, value] of abpMap.entries()) {
                if (value.prgList !== null) {
                    value.prgList.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
                }
                this.abpList.push(value);
            }
            this.abpList.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка выполнения loadFilters', error.toString());
        }
    }

    private regionBudgetList: any[] = [];

    private async loadBudgetRegions() {
        const userUiid = this.userUiid;
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.location.obl + '/' + userUiid);
            this.regionBudgetList = await response.json();
            this.selRegionBudget = this.regionBudgetList[0];
            if (this.regionBudgetList.length === 0) {
                await this.loadBudgetRegions();
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса регионов', error.toString());
        }
    } // справочник регионов

    private dataTypeList: DataType[] = [];

    private async loadDataTypeList() {
        this.dataTypeList = [];
        try {
            this.dataTypeList = await fetch('/api-py/dictionary/budget_data_types/')
                .then(response => response.json());
            if (this.dataTypeList.length > 0) {
                this.selDataType = this.dataTypeList[0];
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки вида данных', error.toString());
        }
    }

    private directionList: any[] = [];

    private async loadDirectionList() {
        try {
            this.directionList = await fetch('/api-py/dictionary/bip_project_type_list/')
                .then(response => response.json());
            this.directionList.sort(sortByField('name_ru'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadDirectionList', error.toString());
        }
    }

    private objectList: any[] = [];

    private async loadObjectList() {
        try {
            this.objectList = await fetch('/api-py/dictionary/bip_project_object_list/')
                .then(response => response.json());
            this.objectList.sort(sortByField('name_ru'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadObjectList', error.toString());
        }
    }

    private userLevel = 0;
    private operationCode: any[] = [];

    private async loadOperations() {
        if (this.userUiid === null) {
            return;
        }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=004.002.007`))
                .then(response => response.json());
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', error.toString());
            return;
        }
        if (result.operations) {
            this.operationCode = result.operations;
        }
        if (result.accessLevel) {
            this.userLevel = result.accessLevel;
        }
    }

    private prevList: any[] = [];

    private async loadPrevList() {
        this.prevList = [];
        const versions = this.versionList.filter(row =>
            (row.is_deleted === null || !row.is_deleted)
            && row.status
            && row.year === this.planPeriod.year - 1
            && row.date_ueb !== null
            && row.region_code === this.selRegionBudget.code)
            .sort(sortByField('date_ueb'));

        if (versions.length > 0) {
            try {
                let response: any;
                response = await fetch('/api-py/bip-forms/' + versions[versions.length - 1].variant_uuid);
                response = await response.json();
                this.prevList = await response.bipList;

                // Для отображения в multiselect код BIP и имени
                this.prevList.forEach(bip => bip.nameCode = `${bip.code} - ${bip.nameRu}`)

                this.prevList = this.prevList.sort(sortByField('code'));
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки prevList', error.toString());
            }
        }
    }

    private regionList: any[] = [];

    private async loadRegions() {
        try {
            let response: any = [];
            response = await fetch('/api-py/get-kato-regions/' + this.location.code);
            response = await response.json();
            for (const region of response) {
                if (region.code.substring(2, 3) !== '0') {
                    try {
                        let locals: any = [];
                        locals = await fetch('/api-py/get-kato-localities/' + region.code.substring(0, 4));
                        locals = await locals.json();
                        locals.unshift({name_ru: ''});
                        this.$set(region, 'localityList', locals);
                        this.regionList.push(region);
                    } catch (error) {
                        makeToast(this, 'danger', 'Ошибка запроса loadRegions', error.toString());
                    }
                }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка loadRegions', error.toString());
        }
    }

    private statusList: any[] = [];

    private async loadStatusList() {
        try {
            this.statusList = await fetch('/api-py/dictionary/agreement_status/')
                .then(response => response.json());
            this.statusList.sort(sortByField('name_ru'));
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadStatusList', error.toString());
        }
    }

    private tabs: any = [];

    private loadTab() {
        if (!this.tabs.includes(this.tabIndex)) {
            this.tabs.push(this.tabIndex);
        }
    } // загрузка при открытии вкладки

    private userAbps: any [] = [];

    private async loadUserAbps() {
        let response: any = [];
        try {
            if (this.userUiid !== null && this.userUiid.length > 0) {
                response = await fetch('/api-py/user-abp/' + this.userUiid);
                response = await response.json();
                for (const abp of response) {
                    this.userAbps.push(abp.abp);
                }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки ABP пользователя', error.toString());
        }
    }

    private userGus: any [] = [];

    private async loadUserGus() {
        let response: any = [];
        try {
            if (this.userUiid !== null && this.userUiid.length > 0) {
                response = await fetch('/api-py/user-gu/' + this.userUiid);
                response = await response.json();
                for (const item of response) {
                    this.userGus.push(item.gu);
                }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки GU пользователя', error.toString());
        }
    }

    private versionList: any[] = [];

    private async loadVersions() {
        this.versionList = [];
        try {
            const response = await fetch('/api-py/dictionary/budget_variants/')
                .then(response => response.json());
            for (const v of response) {
                const str = (v.status ? 'утв' + (v.attribute ? ', акт' : '') : (v.attribute ? 'акт' : ''));
                v.text = v.name_ru + (str.length > 0 ? ' (' + str + ')' : '');
                this.versionList.push(v);
            }
            await this.updateVersionByYR();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadVersions', error.toString());
        }
    }

    private message(form: BipProjectDataClass, sms: string) {
        this.$bvModal.msgBoxConfirm(
            sms, {
                title: 'Внимание!',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Исправить',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                modalClass: 'del-item-modal-script'
            })
            .then(value => {
                this.saving = false;
                if (value) {
                    if (!this.showForm) {
                        this.openBip(form, false);
                    }
                } else {
                    this.showForm = false;
                    this.backList = true;
                    return;
                }
            })
            .catch(error => {
                makeToast(this, 'danger', 'Ошибка проверки контроля', error.toString());
            });
    }

    public modeling() {
        this.showTest = !this.showTest;
        this.$root.$emit('bipModel', true);
        this.$root.$emit('removeClass', true);
    }

    private openBip(bip: BipProjectDataClass, emitOn: Boolean = true) {
        if (emitOn) {
            this.$root.$emit('removeClass');
        }
        this.bipProjectData = bip;
        this.showForm = true;
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        let refItem: any;
        switch (refName) {
            case 'bcPlanYear':
                refItem = this.$refs.bcPlanYear;
                break;
            case 'bcRegionBudget':
                refItem = this.$refs.bcRegionBudget;
                break;
            case 'bcDataType':
                refItem = this.$refs.bcDataType;
                break;
            case 'bcVersion':
                refItem = this.$refs.bcVersion;
                break;
            case 'bcAbp':
                refItem = this.$refs.bcAbp;
                break;
            case 'bcPrg':
                refItem = this.$refs.bcPrg;
                break;
            case 'bcPpr':
                refItem = this.$refs.bcPpr;
                break;
            default:
                break;
        }
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private async postData(url = '', data = {}) {
        // Default options are marked with *
        try {
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            });
            return await response.json(); // parses JSON response into native JavaScript objects
        } catch (error) {
            console.log('Error postData', error.toString());
            return;
        }
    }

    private async reCalculate() {
        this.$bvModal.msgBoxConfirm(
            'Балл будет пересчитан и сохранён. Обновить балл?',
            {
                title: 'Подтверждение',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                cancelVariant: 'light',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                modalClass: 'del-item-modal-script'
            })
            .then(value => {
                if (value) {
                    this.updateMark = true;
                    this.saving = true;
                } else {
                    return;
                }
            })
            .catch(error => {
                makeToast(this, 'danger', 'Ошибка обновления', error.toString());
            });
        this.updateMark = false;
    }

    private async saveBip(form: BipProjectDataClass) {
        if (form && this.bipProjectData) {
            //-------------------проверки--------------//
            // Здесь изменения для task-5079
            if (this.curHeader && ((this.curHeader.selAbp === null || this.curHeader.selGu === null)
                || (this.curHeader.finalAbp === 0 && (this.curHeader.regAbp === null || this.curHeader.regGu === null)))) {
                makeToast(this, 'danger', 'Предупреждение', 'Необходимо перевыбрать гос.учреждение!');
                if (this.bipCurrent) {
                    this.openBip(this.bipCurrent, false);
                }
                this.saving = false;
                return;
            }

            const object = (form.object.length === 0);
            if (object) {
                this.message(form, 'Необходимо указать "Тип объекта" и "Направление проекта"');
                return;
            }

            const considerationGA = (form.isConsiderationCentralGovernment && form.considerationGA === 0);
            if (considerationGA) {
                this.message(form, 'Необходимо заполнить "Сумма запроса ЦТ из РБ, тыс. тенге"');
                return;
            }

            const isDirectCash = (form.isDirectCash && form.directCash === 0);
            if (isDirectCash) {
                this.message(form, 'Необходимо заполнить "Экономическая внутренняя норма доходности (EIRR)"');
                return;
            }

            const fileTextEmpty = form.files.filter(row => row.text.length === 0).length > 0;
            if (fileTextEmpty) {
                this.message(form, 'Необходимо заполнить сведения по прикрепленным документам (вкладка 1.Общие сведения)');
                return;
            }

            if (this.section > 0) {
                this.message(form,
                    "Необходимо выбрать программу/подпрограмму/специфику в разделе " + this.section);
                return;
            }

            const finDouble = this.checkFinDoubles(form);
            if (finDouble > 0) {
                this.message(form, 'Дублирование данных по Программе, Подпрограмме (вкладка 4.Финансирование планового периода)!');
                return;
            }

            const bipDouble = this.checkBipDouble(form);
            if (bipDouble) {
                const dataType = findItem(parseInt(form.header.dataType), 'code', this.dataTypeList);
                this.message(form, 'Данные по проекту ' + form.code
                    + ' с видом данных <<' + dataType.name_ru + '>>'
                    + ' за период ' + parseInt(form.header.year) + '-' + parseInt(form.header.year + 2)
                    + ' уже присутсвуют в системе. Дублирующий проект не сохранится!');
                return;
            }

            const dateExam = form.dateStateExamination === null
                && (!['1', '2', '5', '6'].includes(form.projectType));
            if (dateExam) {
                this.message(form, 'Необходимо заполнить "Дата государственной экспертизы"!');
                return;
            }
            if (form.header?.finalAbp === 1) { // если получателя нет
                if (!checkGu(this.userGus, form)) {
                    this.message(form, 'Гос.учреждение Пользователя должно совпадать с гос.учреждением инициатора.');
                    return;
                }
                if (this.selRegionBudget.code !== form.region_budget) {
                    this.message(form, 'Регион ГУ должен совпадать с Регионом из фильтра.');
                    return;
                }
                const versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                    && row.attribute
                    && row.year === this.planPeriod.year
                    && row.data_type === parseInt(this.selDataType.code)
                    && row.region_code === form?.region_budget);
                if (versions.length === 0) {
                    this.message(form,
                        'Данные не будут сохранены, так как отсутствует актуальная версия бюджета:'
                        + ' Период - ' + this.planPeriod.name + ', Вид данных - ' + this.selDataType.code
                        + ', Регион бюджета - ' + form?.region_budget);
                    return;
                }
                this.$set(form.header, 'dataType', versions[0].data_type);
                this.saveForm(form, versions[0].variant_uuid, '');
            } else { // если получатель имеется
                if (!(this.selRegionBudget.code === form.region_budget && this.editSection4(form))) {
                    if (this.editBip(this.bipProjectData) && !checkGuReg(this.userGus, form)) {
                        this.message(form, 'Гос.учреждение Пользователя должно совпадать с гос.учреждением получателя.');
                        return;
                    }
                    if (this.selRegionBudget.code !== form.district_budget) {
                        this.message(form, 'Регион ГУ получателя должен совпадать с Регионом из фильтра.');
                        return;
                    }
                }
                if (checkGu(this.userGus, form) && this.selRegionBudget.code === form.region_budget) {
                    console.log('сижу под Инициатором')
                    let versions_rec = [];
                    if ((form.variant_recipient !== null) && (form.variant_recipient.length > 0)) {
                        versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                            && row.attribute && row.variant_uuid === form.variant_recipient);
                        if (versions_rec.length === 0) {
                            this.message(form,
                                'Версия бюджета (' + form.variant_recipient + ') по Получателю не актуальна:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + form.header.regional.dataType
                                + ', Регион бюджета - ' + form?.district_budget);
                            return;
                        }
                    }
                    if (form.variant_recipient === null || (form.variant_recipient !== null && form.variant_recipient.length === 0)) {
                        if ([3, 4].includes(parseInt(form.header.dataType))) {
                            versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && [3, 4].includes(row.data_type)
                                && form?.district_budget
                                && row.region_code === form?.district_budget);
                        } else {
                            versions_rec = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && row.data_type === parseInt(form.header.dataType)
                                && form?.district_budget
                                && row.region_code === form?.district_budget);
                        }
                        if (versions_rec.length === 0) {
                            this.message(form,
                                'Отсутствует актуальная версия по Получателю:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + form.header.dataType
                                + ', Регион бюджета - ' + form?.district_budget);
                            return;
                        } else {
                            if (form.id > 0) {
                                await findBipVariantNull(this, form, form.variant, versions_rec[0].variant_uuid);
                                if (this.findDB > 0) {
                                    this.saving = false;
                                    return;
                                }
                            }
                        }
                    }
                    if ((versions_rec[0].data_type === form.header.dataType)
                        || (versions_rec[0].data_type === 4 && form.header.dataType === 3)
                        || (versions_rec[0].data_type === 3 && form.header.dataType === 4)) {
                        this.$set(form.header.regional, 'dataType', versions_rec[0].data_type);
                    }
                    this.$set(form.header, 'dataType', this.selVersion.data_type);
                    this.$set(form.header.regional, 'dataType', versions_rec[0].data_type);
                    this.saveForm(form, form.id === 0 ? this.selVersion.variant_uuid : form.variant, versions_rec[0].variant_uuid);
                }
                if (checkGuReg(this.userGus, form) && this.selRegionBudget.code === form.district_budget) {
                    console.log('сижу под Получателем')
                    let versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                        && row.attribute && row.variant_uuid === form.variant);
                    if ((form.variant !== null) && (form.variant.length > 0) && form.header.editing) {
                        versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                            && row.attribute && row.variant_uuid === form.variant);
                        if (versions.length === 0) {
                            this.message(form,
                                'Версия бюджета (' + form.variant + ') по Инициатору не актуальна:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + form.header.dataType
                                + ', Регион бюджета - ' + form?.region_budget);
                            return;
                        }
                    }

                    if (form.variant === null || (form.variant !== null && form.variant.length === 0) || form.header.editing) {
                        if ([3, 4].includes(parseInt(form.header.regional.dataType))) {
                            versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && [3, 4].includes(row.data_type)
                                && form?.region_budget
                                && row.region_code === form?.region_budget);
                        } else {
                            versions = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
                                && row.attribute
                                && row.year === this.planPeriod.year
                                && row.data_type === parseInt(form.header.regional.dataType)
                                && form?.region_budget
                                && row.region_code === form?.region_budget);
                        }
                        if (versions.length === 0) {
                            this.message(form,
                                'Отсутствует актуальная версия по Инициатору:'
                                + ' Период - ' + this.planPeriod.name
                                + ', Вид данных - ' + form.header.regional.dataType
                                + ', Регион бюджета - ' + form?.region_budget);
                            return;
                        } else {
                            if (form.id > 0) {
                                await findBipVariantRecNull(this, form, versions[0].variant_uuid, form.variant_recipient);
                                if (this.findDB > 0) {
                                    this.saving = false;
                                    return;
                                }
                            }
                        }
                        if ((versions[0].data_type === form.header.regional.dataType)
                            || (versions[0].data_type === 3 && form.header.regional.dataType === 4)
                            || (versions[0].data_type === 4 && form.header.regional.dataType === 3)) {
                            this.$set(form.header, 'dataType', versions[0].data_type);
                        }
                    }

                    this.$set(form.header, 'dataType', versions[0].data_type);
                    this.$set(form.header.regional, 'dataType', this.selVersion.data_type);
                    this.saveForm(form, versions[0].variant_uuid, this.selVersion.variant_uuid);
                }
            }
        }
        this.saving = false;
    }

    private saveForm(form: BipProjectDataClass, variant: string, variant_rec: string) {
        if (form.id === 0) {
            this.$set(form, 'variant', variant);
            this.$set(form, 'variant_recipient', variant_rec);
        }
        if (form.versions) {
            form.versions.push(form.variant);
            form.versions.push(form.variant_recipient);
            form.versions.push(variant.length > 0 ? variant : '');
            form.versions.push(variant_rec.length > 0 ? variant_rec : '');
        }

        const params = JSON.stringify({
            variant: this.selVersion.variant_uuid,
            mode_code: this.mode_code,
            operation_code: this.operationCode
        });
        const newElem = {
            form: form,
            variant: variant,
            variant_recipient: variant_rec,
            user_name: this.userUiid,
            params: params
        };
        const newId = form.id;
        console.log('newElem', newElem)
        this.postData('/api-py/save-bip-form', newElem).then((data) => {
            if (data.result) {
                if (newId === 0) {
                    this.$set(form, 'id', data.id);
                    this.$set(form, 'code', data.code);
                } else {
                    let i = 0;
                    for (const bip of this.bipList) {
                        if (bip.id === newId) {
                            this.bipList.splice(i, 1);
                            break;
                        }
                        i++;
                    }
                }
                this.bipProjectData = BipProjectDataClass.assign(data.bip);
                this.defineBip(this.bipProjectData);
                this.agrMap.set(this.bipProjectData.code, this.bipProjectData.addDatas.status);
                this.bipList.unshift(this.bipProjectData);
                console.log('saveform', this.bipProjectData);

                if (this.showForm) {
                    this.showForm = false;
                    setTimeout(() => {
                        for (const bip of this.bipList) {
                            if (this.bipProjectData && bip.id === this.bipProjectData.id) {
                                this.openBip(this.bipProjectData, false);
                                this.isNotEqual = false;
                                break;
                            }
                        }
                    }, 1000);
                }
                this.saveFinace(form, variant, variant_rec); // save newFinace
                makeToast(this, 'success', 'Сохранение', 'Данные сохранены');
            } else {
                makeToast(this, 'danger', 'Ошибка сохранения', data.error);
            }
        });
        this.saving = false;
    }

    private async saveFinace(form: BipProjectDataClass, variant: any, variant_rec: any) {
        const values = [];
        if (form) {
            if (variant.length > 0) {
                const item = {};
                this.$set(item, 'isDeleted', form?.isDeleted);
                this.$set(item, 'bip_code', form?.code);
                this.$set(item, 'object', form?.object);
                this.$set(item, 'region', form?.region_budget);
                this.$set(item, 'data_type', form.header.dataType);
                this.$set(item, 'abp', form?.header?.abp);
                this.$set(item, 'gu', form?.header?.gu?.code);
                this.$set(item, 'cur_year', form?.header?.year);
                this.$set(item, 'variant', variant);

                const arr = [];
                for (const obj of form?.newFinace) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (parseInt(key) >= parseInt(form?.header?.year)
                            && parseInt(key) <= parseInt(form?.header?.year) + 2) {
                            const val = {
                                gr: obj?.gr,
                                prg: obj?.prg,
                                ppr: obj?.ppr,
                                spf: obj?.spf,
                                year: key,
                                value: value
                            };
                            arr.push(val);
                        }
                    }
                }
                for (const row of arr) {
                    const newRow = Object.assign({}, item);
                    this.$set(newRow, 'gr', row?.gr);
                    this.$set(newRow, 'prg', row?.prg);
                    this.$set(newRow, 'ppr', row?.ppr);
                    this.$set(newRow, 'spf', row?.spf);
                    this.$set(newRow, 'year', row?.year);
                    this.$set(newRow, 'value', row?.value);
                    values.push(newRow);
                }
            }

            // Районный (конечный)
            if (form.header?.finalAbp === 0 && form?.newFinaceRegional && variant_rec.length > 0) {
                const item = {};
                this.$set(item, 'isDeleted', form?.isDeleted);
                this.$set(item, 'bip_code', form?.code);
                this.$set(item, 'object', form?.object);
                this.$set(item, 'region', form?.district_budget);
                this.$set(item, 'data_type', form.header?.regional.dataType);
                this.$set(item, 'abp', form?.header?.regional.abp);
                this.$set(item, 'gu', form?.header?.regional.gu?.code);
                this.$set(item, 'cur_year', form?.header?.year);
                this.$set(item, 'variant', variant_rec);

                const arr = [];
                for (const obj of form?.newFinaceRegional) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (parseInt(key) >= parseInt(form?.header?.year)
                            && parseInt(key) <= parseInt(form?.header?.year) + 2) {
                            const val = {
                                gr: obj?.gr,
                                prg: obj?.prg,
                                ppr: obj?.ppr,
                                spf: obj?.spf,
                                year: key,
                                value: value
                            };
                            arr.push(val);
                        }
                    }
                }

                for (const row of arr) {
                    const newRow = Object.assign({}, item);
                    this.$set(newRow, 'gr', row?.gr);
                    this.$set(newRow, 'prg', row?.prg);
                    this.$set(newRow, 'ppr', row?.ppr);
                    this.$set(newRow, 'spf', row?.spf);
                    this.$set(newRow, 'year', row?.year);
                    this.$set(newRow, 'value', row?.value);
                    values.push(newRow);
                }
            }

            const newElem = {
                'bip_code': form?.code,
                'cur_year': form?.header?.year,
                'data_type': form?.header?.dataType,
                'user_name': this.userUiid,
                'variant': variant,
                'variant_recipient': variant_rec,
                'versions': form?.versions,
                'values': values
            }

            try {
                const response = await fetch('/api-py/save-bip-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(newElem)
                });
                const result = await response.json();
                if (response.status === 200 && result.result === 'success') {
                    makeToast(this, 'success', 'Сообщение', 'Данные сохранены (total)');
                    return true;
                }
            } catch {
                makeToast(this, 'danger', 'Предупреждение', 'Ошибка сохранения данных');
                return false;
            }
        }
    }

    private async scrollMeToList() {
        this.$root.$emit('removeClass');
        this.backList = true;
        await setTimeout(() => {
            if (this.editAccess && this.isNotEqual) {
                this.$bvModal.msgBoxConfirm(
                    'Сохранить изменения?',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.curHeader && ((this.curHeader.selAbp === null || this.curHeader.selGu === null)
                                || (this.curHeader.finalAbp === 0 && (this.curHeader.regAbp === null || this.curHeader.regGu === null)))) {
                                makeToast(this, 'danger', 'Предупреждение', 'Необходимо перевыбрать гос.учреждение!');
                                if (this.bipProjectData) {
                                    this.openBip(this.bipProjectData, false);
                                }
                                return;
                            }
                            if (this.bipCurrent) {
                                this.saveBip(this.bipCurrent);
                            }
                        } else {
                            this.isNotEqual = false;
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка удаления', error.toString());
                    });
            }
            const el = this.$refs.bipList;
            el.scrollIntoView({behavior: 'smooth'});
            this.showForm = false;
        }, 1000);
    } // кнопка назад к списку

    private async scrollMeToListModeling() {
        this.$root.$emit('bipModel', true);
        this.$root.$emit('removeClass', true);
        this.backList = true;
        await setTimeout(() => {
            if (this.editAccess && this.isNotEqual) {
                this.$bvModal.msgBoxConfirm(
                    'Сохранить изменения?(2)',
                    {
                        title: 'Подтверждение',
                        size: 'md',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'ДА',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true,
                        modalClass: 'del-item-modal-script'
                    })
                    .then(value => {
                        if (value) {
                            if (this.curHeader && ((this.curHeader.selAbp === null || this.curHeader.selGu === null)
                                || (this.curHeader.finalAbp === 0 && (this.curHeader.regAbp === null || this.curHeader.regGu === null)))) {
                                makeToast(this, 'danger', 'Предупреждение', 'Необходимо перевыбрать гос.учреждение!');
                                if (this.bipProjectData) {
                                    this.openBip(this.bipProjectData, false);
                                }
                                return;
                            }
                            if (this.bipCurrent) {
                                this.saveBip(this.bipCurrent);
                            }
                        } else {
                            this.isNotEqual = false;
                        }
                    })
                    .catch(error => {
                        makeToast(this, 'danger', 'Ошибка удаления', error.toString());
                    });
            }
            const el = this.$refs.bipList;
            el.scrollIntoView({behavior: 'smooth'});
            this.showForm = false;
        }, 1000);
    } // кнопка назад к списку

    private setFinances(
        finance: RowNewFinanceData[],
        ppr: number,
        finances: {
            plan_1: number;
            plan_2: number;
            plan_3: number;
            fin_0: number;
            fin_1: number;
            fin_2: number;
            fin_3: number;
        }
    ) {
        for (const obj of finance) {
            if (obj.prg === this.selPrg?.prg) {
                for (const [key, value] of Object.entries(obj)) {
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year - 3
                    ) {
                        finances.plan_3 = getNumber(
                            parseFloat(value as string)
                        );
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year - 2
                    ) {
                        finances.plan_2 = getNumber(
                            parseFloat(value as string)
                        );
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year - 1
                    ) {
                        finances.plan_1 = getNumber(
                            parseFloat(value as string)
                        );
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year
                    ) {
                        finances.fin_0 = getNumber(parseFloat(value as string));
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year + 1
                    ) {
                        finances.fin_1 = getNumber(parseFloat(value as string));
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) === this.planPeriod.year + 2
                    ) {
                        finances.fin_2 = getNumber(parseFloat(value as string));
                    }
                    if (
                        (obj.ppr === ppr || (obj.ppr === null && ppr === -1)) &&
                        parseInt(key) > this.planPeriod.year + 2
                    ) {
                        finances.fin_3 += getNumber(
                            parseFloat(value as string)
                        );
                    }
                }
            }
        }
    }

    private setMapPpr(finace: any[], map: Map<any, any>) {
        for (const obj of finace) {
            if (obj.prg === this.selPrg.prg) {
                const pprItem = findItem(parseInt(obj.ppr), 'ppr', this.selPrg.pprList);
                if (pprItem !== null) {
                    switch (obj.ppr) {
                        case 5:
                            this.$set(pprItem, 'source', 'ВЗ');
                            break;
                        case 11:
                            this.$set(pprItem, 'source', 'РБ');
                            break;
                        case 15:
                            this.$set(pprItem, 'source', 'МБ');
                            break;
                        case 32:
                            this.$set(pprItem, 'source', 'НФ');
                            break;
                        default:
                            this.$set(pprItem, 'source', padLeadingZeros(obj.ppr, 3));
                            break;
                    }
                    map.set(obj.ppr, pprItem);
                }
            }
        }
    }

    private updateAgreement() {
        if (this.agrMap) {
            for (const [key, value] of this.agrMap.entries()) {
                const bip = this.bipList.filter(row => row.code === key);
                if (bip.length > 0) {
                    const item = bip[0];
                    this.$set(item['addDatas'], 'status', this.agrMap.get(key));
                }
            }
        }
    }

    private section: number = 0;
    private curHeader: any = undefined;
    private bipCurrent: BipProjectDataClass | null = null;

    private async updateBip(item: any) {
        this.isNotEqual = !item.equal;
        this.bipCurrent = BipProjectDataClass.assign(item.form);
        this.section = item.section;
        this.curHeader = item.curHeader;
        console.log('UPDATE_BIP', item.form, this.isNotEqual);
        if (this.saving) {
            try {
                this.saveBip(this.bipCurrent);
            } catch (error) {
                console.log('error savingBip => ', error.toString());
            } finally {
                this.saving = false;
            }
        }
    }

    async updateVersion() {
        for (const ver of this.filterVersion) {
            if (ver.attribute) {
                this.selVersion = ver;
                break;
            }
        }
        if (!this.filterVersion.includes(this.selVersion)) {
            this.selVersion = this.filterVersion[0];
        }
    }

    async updateVersionByYR() {
        let vers = this.versionList.filter(row => (row.is_deleted === null || !row.is_deleted)
            && row.year === this.planPeriod.year
            && this.selRegionBudget !== undefined
            && row.region_code === this.selRegionBudget.code);
        vers = vers.sort(sortByField('date_ueb', 'desc'));

        for (const ver of vers) {
            if (ver.attribute) {
                this.selVersion = ver;
                this.selDataType = findItem(this.selVersion.data_type, 'code', this.dataTypeList);
                break;
            }
        }

        if (!this.filterVersion.includes(this.selVersion)) {
            this.selVersion = this.filterVersion[0];
        }
    }
}
