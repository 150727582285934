































import { Component, Vue, Prop } from 'vue-property-decorator';

export interface iAgrBtns {
    back: any[],
    forward: any[]
}

@Component({
    name: 'c-budg-agr-modal'
})
export default class CBudgetAgreementModal extends Vue {
    @Prop({
        required: true,
        default: { back: [], forward: [] }
    })
    private agrBtnLst!: iAgrBtns;

    private showModal = false;

    private commentTxt = '';

    private showEvent() {
        this.showModal = true;
    }

    private clkBtn(code: number) {
        const saveObj = { status: code, commentTxt: this.commentTxt };
        this.$emit('agrClk', saveObj);
        this.showModal = false;
        this.commentTxt = '';
    }
}
