<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Тип объекта ------------------->
                        <div class="filter-block">
                            <b-form-group label="Тип объекта">
                                <multiselect ref="bcObject"
                                             v-model="selObject"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Выберите тип объекта"
                                             :options="objectList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Направление ------------------->
                        <div class="filter-block">
                            <b-form-group label="Вид данных">
                                <multiselect ref="bcProject"
                                             v-model="selProject"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Выберите направление"
                                             :options="projectList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Место реализации ------------------->
                        <div class="filter-block">
                            <b-form-group label="Место реализации">
                                <multiselect ref="bcPlace"
                                             v-model="selPlace"
                                             track-by="text"
                                             label="text"
                                             placeholder="Выберите место реализации"
                                             :options="placeList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Критерий ------------------->
                        <div class="filter-block">
                            <b-form-group label="Критерий">
                                <multiselect ref="bcCriteria"
                                             v-model="selCriteria"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Выберите критерий"
                                             :options="bipCifList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>

                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <multiselect v-show="years"
                                             ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             placeholder="Выберите плановый период"
                                             :options="years"
                                             :searchable="false"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <b-button variant="secondary" @click="resetFilter">Сбросить</b-button>
                </b-dropdown>
            </div>
            <div class="right-content">
                <div v-if="host" class="filter-actions">
                    <b-button variant="primary" @click="addItem"><i class="icon icon-plus-circle"></i>Добавить</b-button>
                    <b-button variant="success" @click="save">Сохранить</b-button>
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="!openFilterWindow && selObject" @click="openFilterByRef('bcObject')">
                {{ selObject.name_ru }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selProject" @click="openFilterByRef('bcProject')">
                {{ selProject.name_ru }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selPlace" @click="openFilterByRef('bcPlace')">
                {{ selPlace.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selCriteria" @click="openFilterByRef('bcCriteria')">
                {{ selCriteria.name_ru }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
                {{ planPeriod.name }}
            </span>
        </div>
        <div class="table-container">
            <b-table
                ref="selectableTable"
                :fields="tableFields"
                :items="showLinkList"
                responsive="true"
                head-variant="light"
                bordered
                sticky-header
                no-border-collapse
                table-class="vertical-align-top"
            >
                <template #cell(id)="data">
                    <b-form-group :class="{ 'with-warning': double(data.item) !== null }">
                        <span>{{ data.item.id }}</span>
                        <template v-if="double(data.item) !== null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание дублирование!<br>{{ double(data.item) }}</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(object_type)="data">
                    <b-form-select
                        v-model="data.item.object_type"
                        :options="objectList"
                        value-field="code"
                        text-field="name_ru"
                        @change="setCurLink(data.item)">
                    </b-form-select>
                </template>
                <template #cell(project_type)="data">
                    <b-form-select
                        v-model="data.item.project_type"
                        :options="projectList"
                        value-field="code"
                        text-field="name_ru"
                        @change="setCurLink(data.item)">
                    </b-form-select>
                </template>
                <template #cell(place)="data">
                    <b-form-select
                        v-model="data.item.place"
                        :options="placeList"
                        value-field="value"
                        text-field="text"
                        @change="setCurLink(data.item)">
                        <template #first>
                            <b-form-select-option :value="null"></b-form-select-option>
                        </template>
                    </b-form-select>
                </template>
                <template #cell(criteria_list)="data">
                    <b-form-group>
                        <textarea
                            rows="1"
                            v-model="data.item.criteria_list"
                            @click="showModal(data.item)">
                        </textarea>
                    </b-form-group>
                </template>
                <template #cell(begin_date)="data">
                    <date-picker
                        v-model="data.item.begin_date"
                        @input="setCurLink(data.item)"
                        format="DD.MM.YYYY"
                        placeholder="__-__-____"
                    >
                    </date-picker>
                </template>
                <template #cell(end_date)="data">
                    <date-picker
                        v-model="data.item.end_date"
                        @input="setCurLink(data.item)"
                        format="DD.MM.YYYY"
                        placeholder="__-__-____"
                    >
                    </date-picker>
                </template>
                <template #cell(more)="data">
                    <span v-if="host" class="remove"
                          @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-clear"></i>
                    </span>
                </template>
            </b-table>
        </div>
        <b-modal ref="modal" modal-class="modal-table modal-add-categories" size="xl" hide-footer title="Добавить критерии:">
            <hr>
           <b-row>
               <b-col cols="6">
                   <div class="my-container-tab">
                       <h5>Доступные</h5>
                       <div class="d-flex align-items-end mb-4">
                           <b-form-group label="Поиск" class="w-100 m-0">
                               <b-form-input v-model="availableFilter" aria-label="Шаблон"></b-form-input>
                           </b-form-group>
                           <b-button size="sm" variant="primary" class="ml-2" @click="addAllCriterias">Все >></b-button>
                       </div>

                       <b-list-group class="list-group">
                           <b-list-group-item v-for="item in availableList" :key="item.id">
                               <div class="d-flex mr-4">
                                       <span class="code">{{ item.code }}</span>
                                       <span>{{ item.name_ru }}</span>
                               </div>
                               <b-badge variant="success" @click="addCriteriaToItem(item, true)">
                                   +
                               </b-badge>
                           </b-list-group-item>
                       </b-list-group>
                   </div>
               </b-col>
               <b-col cols="6">
                   <div class="my-container-tab">
                       <h5>Выбранные</h5>
                       <div class="d-flex align-items-end mb-4">
                           <b-form-group label="Поиск" class="w-100 m-0">
                               <b-form-input v-model="selectedFilter"
                                             aria-label="Шаблон"></b-form-input>
                           </b-form-group>
                           <b-button size="sm" variant="danger" class="ml-2" @click="removeAllCriterias">&lt;&lt; Все</b-button>
                       </div>

                       <b-list-group class="list-group">
                           <b-list-group-item v-for="item in selectedList"
                                              :key="item.id">
                               <b-badge variant="danger" class="mr-3" @click="removeCriteriaFromItem(item)">
                                   -
                               </b-badge>
                               <div class="d-flex mr-4">
                                   <span class="code">{{ item.code }}</span>
                                   <span>{{ item.name_ru }}</span>
                               </div>
                           </b-list-group-item>
                       </b-list-group>
                   </div>
               </b-col>
           </b-row>
        </b-modal>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";
import DatePicker from 'vue2-datepicker';
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";

export default {
    name: 'bip-link',
    components: { DatePicker, 'loading': VueElementLoading },
    props: {
        host: undefined
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'id',
                    label: 'id',
                    sortable: true
                },
                {
                    key: 'object_type',
                    label: 'Тип объекта',
                    sortable: true
                },
                {
                    key: 'project_type',
                    label: 'Направление',
                    sortable: true
                },
                {
                    key: 'place',
                    label: 'Место реализации'
                },
                {
                    key: 'criteria_list',
                    label: 'Критерии'
                },
                {
                    key: 'begin_date',
                    label: 'Дата начала'
                },
                {
                    key: 'end_date',
                    label: 'Дата окончания'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],

            objectList: [],
            selObject: null,

            projectList: [],
            selProject: null,

            placeList: [
                {value: 1, text: 'город'},
                {value: 2, text: 'село'}
            ],
            selPlace: null,

            bipCifList: [],
            selCriteria: null,

            bipLinkTypes: [],
            curLink: undefined,
            curId: 0,
            availableFilter: '',
            selectedFilter: '',

            years: [
                {year: CUR_YEAR, name: `${CUR_YEAR}-${CUR_YEAR + 2}`},
                {year: CUR_YEAR + 1, name: `${CUR_YEAR + 1}-${CUR_YEAR + 3}`},
                {year: CUR_YEAR + 2, name: `${CUR_YEAR + 2}-${CUR_YEAR + 4}`}
            ],
            planPeriod: null,
            openFilterWindow: false,
            user: undefined,
            loading: false
        };
    },
    mounted() {
        this.user = store.state.user.sub;
        this.loadObjectTypes();
        this.loadProjectTypes();
        this.loadBipCifList();
        this.loadDatas();
    },

    computed: {
        availableList() {
            let result = [];
            if (this.curLink) {
                result = this.bipCifList.filter(row => !this.curLink.criteria_list.includes(row.code));
            } else {
                result = this.bipCifList;
            }
            if (this.availableFilter.length > 0) {
                result = result.filter(row => row.name_ru.toLocaleLowerCase().includes(this.availableFilter.toLocaleLowerCase())
                    || (row.name_kz !== null && row.name_kz.toLocaleLowerCase().includes(this.availableFilter.toLocaleLowerCase())));
            }
            return result;
        }, // список доступных критериев

        selectedList() {
            let result = [];
            if (this.curLink) {
                result = this.bipCifList.filter(row => this.curLink.criteria_list.includes(row.code));
            }
            if (this.selectedFilter.length > 0) {
                result = result.filter(row => row.name_ru.toLocaleLowerCase().includes(this.selectedFilter.toLocaleLowerCase())
                    || (row.name_kz !== null && row.name_kz.toLocaleLowerCase().includes(this.selectedFilter.toLocaleLowerCase())));
            }
            return result;
        },

        showLinkList() {
            let result = this.bipLinkTypes.filter(row => !row.is_deleted);

            if (this.selObject) {
                result = result.filter(row => row.object_type === this.selObject.code);
            }

            if (this.selProject) {
                result = result.filter(row => row.project_type === this.selProject.code);
            }

            if (this.selPlace) {
                result = result.filter(row => row.place === this.selPlace.value);
            }

            if (this.selCriteria) {
                result = result.filter(row => row.criteria_list.includes(this.selCriteria.code));
            }

            if (this.planPeriod) {
                result = result.filter(row => row.begin_year <= this.planPeriod.year
                    && (row.end_date === null || (row.end_date !== null && row.end_year >= this.planPeriod.year)));
            }
            return result;
        }
    },

    methods: {
        addItem() {
            const item = {
                id: this.curId--,
                object_type: null,
                project_type: null,
                place: null,
                criteria_list: [],
                begin_date: null,
                end_date: null,
                edit: true,
                more: false,
                // deleted: false,
                begin_year: null,
                end_year: null,
                is_deleted: false
            }
            this.bipLinkTypes.unshift(item);
        },

        addAllCriterias() {
            this.$set(this.curLink, 'criteria_list', []);
            for (const row of this.bipCifList) {
                this.curLink.criteria_list.push(row.code);
            }
        },

        addCriteriaToItem(item, save) {
            this.curLink.criteria_list.push(item.code);
        },

        checkDates() {
            for (const row of this.bipLinkTypes) {
                if (row.edit) {
                    this.setCurLink(row);
                    if (row.end_date !== null) {

                        const bd = new Date(row.begin_date);
                        const ed = new Date(row.end_date);
                        if (bd > ed) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },

        checkDouble() {
            const result = {
                pairs: [],
                res: false
            }

            for (const row of this.bipLinkTypes.filter(row => !row.is_deleted)) {
                const d = this.double(row);
                if (d !== null) {
                    result.res = true;
                    result.pairs.push(d + ' = ' + row.id);
                }
            }
            return result;
        },

        checkRequired() {
            for (const item of this.bipLinkTypes) {
                if (item.edit) {
                    if (item.object_type === null
                        && item.project_type === null
                        && item.criteria_list.length === 0) {
                        // this.$set(item, 'deleted', true);
                        this.$set(item, 'is_deleted', true);
                        this.$set(item, 'edit', false);
                        continue;
                    }
                    if (item.object_type === null
                        || item.project_type === null
                        || item.criteria_list.length === 0
                        || item.begin_date === null) {
                        return false;
                    }
                }
            }
            return true;
        },

        async checkPresValues(link) {
            const response = await fetch('/api-py/get-criteria-values-by-link/' + link);
            const result = await response.json();
            return result;
        },

        async deleteItem(item, index) {
            let res = false;
            if (item.id > 0) {
                res = await this.checkPresValues(item.id);
            }

            const object = this.getItem(item.object_type, this.objectList, 'code');
            const project = this.getItem(item.project_type, this.projectList, 'code');
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"'
                + (object === null ? '' : object.name_ru) + ' - '
                + (project === null ? '' : project.name_ru) + ' - ' + item.place + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (item.id <= 0) {
                            this.bipLinkTypes.splice(this.bipLinkTypes.findIndex(v => v.id === item.id), 1);
                            // this.loadDatas();
                            this.makeToast('success', 'Сообщение', 'Элемент удален');
                        } else {
                            if (res) {
                                this.$bvModal.msgBoxConfirm(
                                    'Связку невозможно удалить, так как по ней введены данные. Очистите данные и попробуйте удаление связки снова',
                                    {
                                        title: 'Подтверждение',
                                        size: 'lg',
                                        buttonSize: 'sm',
                                        okVariant: 'danger',
                                        okTitle: 'Исправить',
                                        cancelTitle: 'Отмена',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true
                                    })
                                    .then(value => {
                                        return;
                                    })
                                    .catch(error => {
                                        this.makeToast('danger', 'Ошибка удаления', error.toString());
                                    });
                            } else {
                                this.delete(item, index);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        async delete(item, index) {
            this.$set(item, 'table', 'bip_link_types')
            const response = await fetch('/api-py/delete-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (result.result === 'success') {
                await this.loadDatas();
                this.makeToast('success', 'Сообщение', 'Элемент удален');
            }
        },

        double(item) {
            for (const row of this.bipLinkTypes.filter(row => !row.is_deleted && row.id !== item.id)) {
                if (row.object_type === item.object_type
                    && row.project_type === item.project_type
                    && row.place === item.place
                    && (item.end_year === null || (item.end_year !== null && row.begin_year <= item.end_year))
                    && (row.end_year === null || (row.end_year !== null && item.begin_year <= row.end_year))) {
                        return row.id;
                    }
            }
            return null;
        },

        getItem (code, list, field) {
            if (code !== null) {
                for (const item of list) {
                    if (item.hasOwnProperty(field)) {
                        if (item[field] !== null && item[field].toString() === code.toString()) {
                            return item;
                        }
                    }
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + row[k] + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        hideModal() {
            this.$refs.modal.hide();
        }, // прячет модальное окно с кнопками

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadBipCifList() {
            try {
                const response = await fetch('/api-py/dictionary/bip_cif_list');
                this.bipCifList = await response.json();
                this.bipCifList.sort(this.sortByField('code'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBipCifList', error.toString());
            }
        },

        async loadDatas() {
            this.loading = true;
            this.bipLinkTypes.splice(0);
            try {
                const response = await fetch('/api-py/dictionary/bip_link_types');
                this.bipLinkTypes = await response.json();
                this.bipLinkTypes.sort(this.sortByField('id'));

                let links = null;
                const responseLinks = await fetch('/api-py/dictionary/bip_link_criterias');
                links = await responseLinks.json();

                const mapLink = new Map();
                for (const row of links) {
                    let item = mapLink.get(row.link);
                    if (item ===  undefined) {
                        item = [];
                    }
                    item.push(row.criteria);
                    mapLink.set(row.link, item);
                }

                for (const row of this.bipLinkTypes) {
                    this.$set(row, 'more', false);
                    this.$set(row, 'edit', false);
                    // this.$set(row, 'deleted', false);

                    const dt = new Date(row.begin_date);
                    this.$set(row, 'begin_date', dt);
                    this.$set(row, 'begin_year', dt.getFullYear());
                    if (row.end_date !== null) {
                        const dt = new Date(row.end_date);
                        this.$set(row, 'end_date', dt);
                        this.$set(row, 'end_year', dt.getFullYear());
                    } else {
                        this.$set(row, 'end_year', null);
                    }

                    let list = mapLink.get(row.id);
                    if (list === undefined) {
                        list = [];
                    }
                    this.$set(row, 'criteria_list', list);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
            }
            this.loading = false;
        },

        async loadObjectTypes() {
            try {
                const response = await fetch('/api-py/dictionary/bip_project_object_list');
                this.objectList = await response.json();
                this.objectList.sort(this.sortByField('name_ru'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict', error.toString());
            }
        },

        async loadProjectTypes() {
            try {
                const response = await fetch('/api-py/dictionary/bip_project_type_list');
                this.projectList = await response.json();
                this.projectList.sort(this.sortByField('name_ru'));
            } catch
                (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict', error.toString());
            }
        },

        makeToast(variant = null, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },

        message(sms) {
            this.$bvModal.msgBoxConfirm(
                sms, {
                    title: 'Внимание!',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Исправить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    return;
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка проверки контроля', error.toString());
                });
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        removeAllCriterias() {
            this.$set(this.curLink, 'criteria_list', []);
        },

        removeCriteriaFromItem(item) {
            const ind = this.curLink.criteria_list.indexOf(item.code);
            this.curLink.criteria_list.splice(ind, 1);
        },

        resetFilter() {
            this.selObject = null;
            this.selProject = null;
            this.selPlace = null;
            this.selCriteria = null;
            this.planPeriod = null;
        },

        async save() {
            const checkRequired = this.checkRequired();
            if (!checkRequired) {
                this.message('Данные не будут сохранены: Условия обязательных полей не выполнено!');
            }

            const checkDates = this.checkDates();
            if (!checkDates) {
                this.message('Данные не будут сохранены: Дата начала должна быть меньше чем дата окончания!');
            }

            const checkDouble = this.checkDouble().res;
            if (checkDouble) {
                this.message('Имеются дубли по связкам. Необходимо объединить связки или изменить их период действия!');
            }

            if (checkRequired && checkDates && !checkDouble) {
                const items = [];
                for (const row of this.bipLinkTypes) {
                    if (row.edit) {
                        this.$set(row, 'user_name', this.user);
                        items.push(row);
                    }
                }

                if (items.length > 0) {
                    const response = await fetch('/api-py/save-links', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(items)
                    });
                    const result = await response.json();
                    if (result.result === 'success') {
                        await this.loadDatas();
                        this.makeToast('success', 'Сообщение', 'Элемент сохранен')
                    }
                } else {
                    await this.loadDatas();
                }
            }
        },

        setCurLink(item) {
            this.curLink = item;
            this.$set(this.curLink, 'edit', true);
            if (this.curLink.begin_date !== null) {
                const dt = new Date(this.curLink.begin_date);
                dt.setMonth(0, 1);
                dt.setHours(6, 0, 0, 0);
                this.$set(this.curLink, 'begin_date', dt);
                this.$set(this.curLink, 'begin_year', dt.getFullYear());
            }
            if (this.curLink.end_date !== null) {
                const dt = new Date(this.curLink.end_date);
                dt.setMonth(11, 31);
                dt.setHours(6, 0, 0, 0);
                this.$set(this.curLink, 'end_date', dt);
                this.$set(this.curLink, 'end_year', dt.getFullYear());
            }
        }, // назначение текущей редактируемой связки

        showModal(item) {
            this.setCurLink(item);
            this.$refs.modal.show();
        }, // показать модальное окно

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }
    }
};
</script>

<style>
    .is-hidden {
        display: none !important;
    }
</style>
<style scoped>
    .item-tool {
        margin-top: 5px;
    }
</style>