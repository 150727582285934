<template>
    <div>
        <!--     фильтры/кнопки     -->
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Группа критериев ------------------->
                        <div class="filter-block">
                            <b-form-group label="Группа критериев">
                                <multiselect ref="bcCritType"
                                             v-model="selCritType"
                                             track-by="text"
                                             label="text"
                                             placeholder="Выберите группу критериев"
                                             :options="criteriaTypeList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Логика расчета ------------------->
                        <div class="filter-block">
                            <b-form-group label="Логика расчета">
                                <multiselect ref="bcCalcType"
                                             v-model="selCalcType"
                                             track-by="text"
                                             label="text"
                                             placeholder="Выберите логику расчета"
                                             :options="calcList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- АБП/CSI ------------------->
                        <div class="filter-block">
                            <b-form-group label="АБП/CSI">
                                <multiselect ref="bcOper"
                                             v-model="selOper"
                                             track-by="value"
                                             label="value"
                                             placeholder="Выберите АБП/CSI"
                                             :options="operList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Расчетное/вводимое ------------------->
                        <div class="filter-block">
                            <b-form-group label="Расчетное/вводимое">
                                <multiselect ref="bcSource"
                                             v-model="selSourceType"
                                             track-by="text"
                                             label="text"
                                             placeholder="Выберите Расчетное/вводимое"
                                             :options="sourceList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Показать закрытые ------------------->
                        <div class="filter-block">
                                <b-form-group class="medium no-label" label="Показать закрытые">
                                    <b-form-checkbox switch
                                                     v-model="isClosed">
                                    </b-form-checkbox>
                                </b-form-group>
                        </div>
                    </div>
                    <br>
                    <b-button variant="secondary" @click="resetFilter">Сбросить</b-button>
                </b-dropdown>
            </div>
            <div class="right-content">
                <div class="filter-actions" v-if="host">
                    <b-button variant="primary" @click="addItem"><i class="icon icon-plus-circle"></i>Добавить</b-button>
                    <b-button variant="success" @click="save">Сохранить</b-button>
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="!openFilterWindow && selCritType" @click="openFilterByRef('bcCritType')">
                {{ selCritType.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selCalcType" @click="openFilterByRef('bcCalcType')">
                {{ selCalcType.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selOper" @click="openFilterByRef('bcOper')">
                {{ selOper.value }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selSourceType" @click="openFilterByRef('bcSource')">
                {{ selSourceType.text }}
            </span>
        </div>
        <div class="table-container">
            <b-table
                ref="selectableTable"
                :fields="tableFields"
                :items="showCifList"
                responsive="true"
                head-variant="light"
                bordered
                sticky-header
                table-class="vertical-align-top"
                no-border-collapse>
                <template #cell(code)="data">
                    <div :class="{ 'error': data.item.code === null || data.item.code.toString().length === 0 }">
                        <b-form-input class="text-right"
                                      :disabled="data.item.id > 0"
                                      v-model="data.item.code"
                                      @change="setCurCriteria(data.item)">
                        </b-form-input>
                        <template v-if="data.item.code === null || data.item.code.toString().length === 0">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание!</p>
                                <p>Поле обязательное для заполнения!</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <b-form-group>
                        <div :class="{ 'error': data.item.name_ru === null || data.item.name_ru.toString().length === 0 }">
                            <b-form-textarea
                                rows="3"
                                max-rows="5"
                                class="w-250px"
                                      v-model="data.item.name_ru"
                                      @change="setCurCriteria(data.item)">
                           </b-form-textarea>
                            <template v-if="data.item.name_ru === null || data.item.name_ru.toString().length === 0">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание!</p>
                                    <p>Поле обязательное для заполнения!</p>
                                </div>
                            </template>
                        </div>
                    </b-form-group>
                </template>
                <template #cell(name_kz)="data">
                    <b-form-group>
                        <div :class="{ 'error': data.item.name_kz === null || data.item.name_kz.toString().length === 0 }">
                            <b-form-textarea
                                rows="3"
                                max-rows="5"
                                class="w-250px"
                                  v-model="data.item.name_kz"
                                  @change="setCurCriteria(data.item)">
                           </b-form-textarea>
                            <template v-if="data.item.name_kz === null || data.item.name_kz.toString().length === 0">
                                <i class="icon icon-danger"></i>
                                <div class="pop-up">
                                    <p class="red-text">Внимание!</p>
                                    <p>Поле обязательное для заполнения!</p>
                                </div>
                            </template>
                        </div>
                    </b-form-group>
                </template>
                <template #cell(criteria_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.criteria_type === null }">
                        <b-form-select v-model="data.item.criteria_type"
                                       :options="criteriaTypeList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.criteria_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(unit_item)="data">
                    <b-form-group :class="{ 'with-warning': data.item.unit_item === null }">
                        <multiselect
                            class="units_select"
                            v-model="data.item.unit_item"
                            track-by="national_symbol"
                            label="national_symbol"
                            placeholder="Выбрать ед.изм"
                            :options="dictUnit"
                            :searchable="true"
                            :allow-empty="false"
                            @input="setCurCriteria(data.item)">
                            <template>
                                <strong>{{ data.item.unit_item }}</strong>
                            </template>
                        </multiselect>
                        <template v-if="data.item.unit_item === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>

                </template>
                <template #cell(data_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.data_type === null}">
                        <b-form-select v-model="data.item.data_type"
                                       :options="dataTypeList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.data_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(source_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.source_type === null }">
                        <b-form-select
                            v-model="data.item.source_type"
                            :options="sourceList"
                            value-field="value"
                            text-field="text"
                            @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.source_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(calc_type)="data">
                    <b-form-group :class="{ 'with-warning': data.item.criteria_type !== '4' && data.item.calc_type === null }">
                        <b-form-select v-model="data.item.calc_type"
                                       :options="calcList"
                                       value-field="value"
                                       text-field="text"
                                       @change="setCurCriteria(data.item)">
                            <template #first>
                                <b-form-select-option :value="null"></b-form-select-option>
                            </template>
                        </b-form-select>
                        <template v-if="data.item.criteria_type !== '4' && data.item.calc_type === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(operator)="data">
                    <b-form-group :class="{ 'with-warning': data.item.source_type === 'W' && data.item.operator === null }">
                        <b-form-select :disabled="data.item.source_type !== 'W'"
                                       v-model="data.item.operator"
                                       :options="operList"
                                       value-field="value"
                                       text-field="value"
                                       @change="setCurCriteria(data.item)">
                        </b-form-select>
                        <template v-if="data.item.source_type === 'W' && data.item.operator === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(begin_date)="data">
                    <b-form-group :class="{ 'with-warning': data.item.begin_date === null }">
                        <date-picker
                            v-model="data.item.begin_date"
                            @input="setCurCriteria(data.item)"
                            format="DD.MM.YYYY"
                            placeholder="__-__-____"
                        >
                        </date-picker>
                        <template v-if="data.item.begin_date === null">
                            <div class="form-tooltip">
                                <i class="icon icon-danger"></i>
                                <div class="form-tooltip-content">
                                    <p class="red mb-2">Внимание!<br>Поле обязательное для заполнения!</p>
                                </div>
                            </div>
                        </template>
                    </b-form-group>
                </template>
                <template #cell(end_date)="data">
                    <date-picker
                        v-model="data.item.end_date"
                        @input="setCurCriteria(data.item)"
                        format="DD.MM.YYYY"
                        placeholder="__-__-____"
                    ></date-picker>
                </template>
                <template #cell(more)="data">
                    <span v-if="host" class="remove"
                          @click="deleteItem(data.item, data.index)">
                        <i class="icon icon-clear"></i>
                    </span>
                </template>
            </b-table>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import DatePicker from 'vue2-datepicker';
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";

export default {
    name: 'bip-test',
    components: { DatePicker, 'loading': VueElementLoading },
    props: {
        host: undefined
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'id',
                    label: 'id',
                    sortable: true
                },
                {
                    key: 'code',
                    label: 'code',
                    sortable: true
                },
                {
                    key: 'name_ru',
                    label: 'Наименование на русском',
                    sortable: true
                },
                {
                    key: 'name_kz',
                    label: 'Наименование на казахском',
                    sortable: true,
                    variant: 'warning'
                },
                {
                    key: 'criteria_type',
                    label: 'Группа критериев',
                    sortable: true
                },
                {
                    key: 'unit_item',
                    label: 'Ед.изм'
                },
                {
                    key: 'data_type',
                    label: 'Тип данных'
                },
                {
                    key: 'source_type',
                    label: 'Расчетное (Р)/ вводимое (В)'
                },
                {
                    key: 'calc_type',
                    label: 'Логика расчета'
                },
                {
                    key: 'operator',
                    label: 'АБП/CSI'
                },
                {
                    key: 'begin_date',
                    label: 'Дата начала действия'
                },
                {
                    key: 'end_date',
                    label: 'Дата окончания действия'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],

            criteriaTypeList: [
                {value: '1', text: 'Бюджетные'},
                {value: '2', text: 'Влияние на ЦИ'},
                {value: '3', text: 'Потребность'},
                {value: '4', text: 'Дополнительные'}
            ],
            selCritType: null,

            calcList: [
                {value: '3VL', text: '3VL – Трехзначная'},
                {value: 'B', text: 'B – Бинарная'},
                {value: 'E', text: 'E – Специфичная'},
                {value: 'E*', text: 'E* - Специфичная (срок действия ГЭ)'},
                {value: 'N', text: 'N – однонаправленная'},
                {value: 'R', text: 'R - Разнонаправленная'}
            ],
            selCalcType: null,

            dataTypeList: [
                {value: 'number', text: 'числовой'},
                {value: 'boolean', text: 'логический (да, нет)'},
                {value: 'int', text: 'тип со значениями (-1, 0,1)'}
            ],

            sourceList: [
                {value: 'R', text: 'Р'},
                {value: 'W', text: 'В'}
            ],
            selSourceType: null,

            operList: [
                {value: 'АБП'},
                {value: 'CSI'}
            ],
            selOper: null,

            isClosed: false,
            bipCifList: [],
            dictUnit: [],
            curId: 0,
            openFilterWindow: false,
            user: undefined,
            loading: false
        };
    },
    async mounted() {
        this.user = store.state.user.sub;
        await this.loadUnits();
        await this.loadDict();
    },
    computed: {
        showCifList() {
            const now_year = new Date().getFullYear();

            let result = this.bipCifList.filter(row => !row.is_deleted);

            if (!this.isClosed) {
                result = result.filter(row => row.end_date === null || (row.end_date !== null && new Date(row.end_date).getFullYear() >= now_year));
            }

            if (this.selCritType) {
                console.log('selCritType', this.selCritType);
                result = result.filter(row => row.criteria_type === this.selCritType.value);
            }

            if (this.selCalcType) {
                result = result.filter(row => row.calc_type === this.selCalcType.value);
            }

            if (this.selSourceType) {
                result = result.filter(row => row.source_type === this.selSourceType.value);
            }

            if (this.selOper) {
                result = result.filter(row => row.operator === this.selOper.value);
            }
            return result;
        }
    },
    methods: {
        addItem() {
            const item = {
                id: this.curId--,
                code: null,
                name_ru: null,
                name_kz: null,
                criteria_type: null,
                unit_item: null,
                unit: null,
                data_type: null,
                source_type: '',
                calc_type: null,
                operator: null,
                begin_date: null,
                end_date: null,
                is_deleted: false,
                edit: true,
                more: false
            }
            this.bipCifList.unshift(item);
        },

        async checkContent(code) {
            const response = await fetch('/api-py/get-links-by-criteria/' + code);
            const result = await response.json();
            console.log('response', result);
            return result;
        },

        checkDates() {
            for (const row of this.bipCifList) {
                if (row.edit) {
                    if (row.end_date !== null) {
                        const bd = new Date(row.begin_date);
                        const ed = new Date(row.end_date);
                        if (bd > ed) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },

        checkRequired() {
            for (const item of this.bipCifList) {
                if (item.edit) {
                    if (item.criteria_type !== null && item.criteria_type !== '4') {
                        if (item.code === null
                            || item.name_ru === null
                            || item.criteria_type === null
                            || item.unit === null
                            || item.data_type === null
                            || item.source_type === null
                            || item.source_type.toString().length === 0
                            || item.calc_type === null
                            || item.begin_date === null) {
                            return false;
                        }
                        if (item.source_type === 'W') {
                            if (item.operator === null) {
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        },

        async deleteItem(item, index) {
            let res = false;
            if (item.id > 0) {
                res = await this.checkContent(item.code);
            }
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (item.id <= 0) {
                            this.bipCifList.splice(this.bipCifList.findIndex(v => v.id === item.id), 1);
                            // this.loadDatas();
                            this.makeToast('success', 'Сообщение', 'Элемент удален');
                        } else {
                            if (res) {
                                this.$bvModal.msgBoxConfirm(
                                    'Критерий невозможно удалить, так как он используется в Связке. Исключите критерий из Связки и попробуйте удаление критерия снова',
                                    {
                                        title: 'Подтверждение',
                                        size: 'lg',
                                        buttonSize: 'sm',
                                        okVariant: 'danger',
                                        okTitle: 'Исправить',
                                        cancelTitle: 'Отмена',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true
                                    })
                                    .then(value => {
                                        return;
                                    })
                                    .catch(error => {
                                        this.makeToast('danger', 'Ошибка удаления', error.toString());
                                    });
                            } else {
                                this.delete(item, index);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        async delete(item, index) {
            this.$set(item, 'table', 'bip_cif_list')
            const response = await fetch('/api-py/delete-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (result.result === 'success') {
                await this.loadDict();
                this.makeToast('success', 'Сообщение', 'Элемент удален');
            }
        },

        getItem (code, list, field) {
            if (code !== null) {
                for (const item of list) {
                    if (item.hasOwnProperty(field)) {
                        if (item[field] !== null && item[field].toString() === code.toString()) {
                            return item;
                        }
                    }
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDict() {
            this.loading = true;
            try {
                const response = await fetch('/api-py/dictionary/bip_cif_list');
                this.bipCifList = await response.json();
                this.bipCifList.sort(this.sortByField('id'));

                for (const row of this.bipCifList) {
                    this.$set(row, 'more', false);
                    this.$set(row, 'edit', false);
                    this.$set(row, 'unit_item', this.getItem(row.unit, this.dictUnit, 'national_symbol'));

                    const dt = new Date(row.begin_date);
                    this.$set(row, 'begin_date', dt);
                    this.$set(row, 'begin_year', dt.getFullYear());
                    if (row.end_date !== null) {
                        const dt = new Date(row.end_date);
                        this.$set(row, 'end_date', dt);
                        this.$set(row, 'end_year', dt.getFullYear());
                    } else {
                        this.$set(row, 'end_year', null);
                    }
                }
                console.log('bipCifList', this.bipCifList);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict', error.toString());
            }
            this.loading = false;
        },

        async loadUnits() {
            try {
                const response = await fetch('/api-py/dictionary/unit');
                this.dictUnit = await response.json();
                this.dictUnit.sort(this.sortByField('code'));
                console.log('dictUnit', this.dictUnit);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadUnits', error.toString());
            }
        },

        makeToast(variant = null, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },

        message(sms) {
            this.$bvModal.msgBoxConfirm(
                sms, {
                    title: 'Внимание!',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Исправить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    return;
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка проверки контроля', error.toString());
                });
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        resetFilter() {
            this.selCritType = null;
            this.selSourceType = null;
            this.selOper = null;
            this.selCalcType = null;
            this.isClosed = false;
        },

        async save() {
            const checkRequired = this.checkRequired();
            if (!checkRequired) {
                this.message('Данные не будут сохранены: Условия обязательных полей не выполнено!');
                return;
            }
            const checkDates = this.checkDates();
            if (!checkDates) {
                this.message('Данные не будут сохранены: Дата начала должна быть меньше чем дата окончания!');
                return;
            }

            if (checkDates && checkRequired) {
                const items = [];
                for (const row of this.bipCifList) {
                    if (row.edit) {
                        this.$set(row, 'user_name', this.user);
                        items.push(row);
                    }
                }
                console.log('items saving', items);
                const response = await fetch('/api-py/save-bip-cif', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(items)
                });
                const result = await response.json();
                if (result.result === 'success') {
                    await this.loadDict();
                    this.makeToast('success', 'Сообщение', 'Элемент сохранен')
                }
            }
        },

        setCurCriteria(item) {
            this.$set(item, 'edit', true);
            if (item.begin_date !== null) {
                const dt = new Date(item.begin_date);
                dt.setMonth(0, 1);
                dt.setHours(6, 0, 0, 0);
                this.$set(item, 'begin_date', dt);
                this.$set(item, 'begin_year', dt.getFullYear());
            }
            if (item.end_date !== null) {
                const dt = new Date(item.end_date);
                dt.setMonth(11, 31);
                dt.setHours(6, 0, 0, 0);
                this.$set(item, 'end_date', dt);
                this.$set(item, 'end_year', dt.getFullYear());
            }

            if (item.source_type !== 'W') {
                item.operator = null;
            }
            if (item.criteria_type === '4') {
                item.calc_type = null;
            }
            this.$set(item, 'unit', item.unit_item !== null ? item.unit_item.national_symbol : null);
        },

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }
    }
};
</script>

<style>
    .is-hidden {
        display: none !important;
    }
</style>
<style scoped>
    .item-tool {
        margin-top: 5px;
    }
</style>