<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <!--------------- Тип объекта ------------------->
                        <div class="filter-block">
                            <b-form-group label="Тип объекта">
                                <multiselect ref="bcObject"
                                             v-model="selObject"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Выберите тип объекта"
                                             :options="objectList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Направление ------------------->
                        <div class="filter-block">
                            <b-form-group label="Направление">
                                <multiselect ref="bcProject"
                                             v-model="selProject"
                                             track-by="name_ru"
                                             label="name_ru"
                                             placeholder="Выберите направление"
                                             :options="projectList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Место реализации ------------------->
                        <div class="filter-block">
                            <b-form-group label="Место реализации">
                                <multiselect ref="bcPlace"
                                             v-model="selPlace"
                                             track-by="text"
                                             label="text"
                                             placeholder="Выберите место реализации"
                                             :options="placeList"
                                             :searchable="true"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                        <!--------------- Плановый период ------------------->
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <multiselect v-show="years"
                                             ref="bcPlanYear"
                                             v-model="planPeriod"
                                             track-by="name"
                                             label="name"
                                             placeholder="Выберите плановый период"
                                             :options="years"
                                             :searchable="false"
                                             :allow-empty="true"
                                             :show-labels="false">
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <b-button variant="secondary" @click="resetFilter">Сбросить</b-button>
                </b-dropdown>
            </div>
            <div class="right-content">
                <div class="filter-actions">
                    <b-button variant="primary"
                              @click="duplicate"
                              :disabled="!(this.selObject && this.selProject && this.selPlace && this.planPeriod)">
                        <i class="icon icon-plus-circle"></i>Дублировать
                    </b-button>
                    <b-button variant="success"
                              @click="save"
                              :disabled="!(this.selObject && this.selProject && this.selPlace && this.planPeriod)">
                        Сохранить
                    </b-button>
                </div>
            </div>
        </div>
        <!--        хлебные крошки-->
        <div class="filter-breadcrumb">
            <span class="item-breadcrumb" v-if="!openFilterWindow && selObject" @click="openFilterByRef('bcObject')">
                {{ selObject.name_ru }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selProject" @click="openFilterByRef('bcProject')">
                {{ selProject.name_ru }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && selPlace" @click="openFilterByRef('bcPlace')">
                {{ selPlace.text }}
            </span>
            <span class="item-breadcrumb" v-if="!openFilterWindow && planPeriod" @click="openFilterByRef('bcPlanYear')">
                {{ planPeriod.name }}
            </span>
        </div>
        <div class="table-container">
            <b-table
                ref="selectableTable"
                :fields="tableFields"
                :items="showTable"
                :tbody-tr-class="rowClass"
                responsive="true"
                head-variant="light"
                bordered
                sticky-header
                no-border-collapse>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="open"></i>
                        <i class="icon icon-chevron-circle" v-if="!open"></i>
                    </b-button>
                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.par_id === 0" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(name_ru)="data">
                    <div>{{ data.item.name_ru }}</div>
                </template>
                <template #cell(unit_item)="data">
                    <div>{{ data.item.unit }}</div>
                </template>
                <template #cell(value)="data">
                    <b-form-input v-if="data.item.par_id > 0 && data.item.data_type === 'number'"
                                  class="text-right"
                                  :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')"
                                  :value="$n(data.item.value)"
                                  @change="v => data.item.value = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'value', data.item.value, 1)">
                    </b-form-input>
                    <template v-if="data.item.par_id > 0 && data.item.data_type === 'boolean'">
                        <b-form-group class="medium no-label"
                                      :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')">
                            <b-form-checkbox switch
                                             v-model="data.item.value"
                                             @change="data.item.edit=true"
                            ></b-form-checkbox>
                        </b-form-group>
                    </template>
                    <template v-if="data.item.par_id > 0 && data.item.data_type === 'int'">
                        <b-form-group class="radio-horizontal">
                            <b-form-radio-group
                                v-model="data.item.value"
                                :disabled="!(data.item.source_type === 'W' && data.item.operator === 'CSI')"
                                :options="radioOptions"
                                name="radio-inline"
                                @change="data.item.edit=true">
                            </b-form-radio-group>
                        </b-form-group>
                    </template>
                </template>
                <template #cell(weight)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.weight)"
                                  @change="v => data.item.weight = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'weight', data.item.weight, 2)">
                    </b-form-input>
                </template>
                <template #cell(max)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.max)"
                                  @change="v => data.item.max = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'max', data.item.max, 1)">
                    </b-form-input>
                </template>
                <template #cell(min)="data">
                    <b-form-input v-if="data.item.par_id > 0"
                                  class="text-right"
                                  :value="$n(data.item.min)"
                                  @change="v => data.item.min = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'min', data.item.min, 1)">
                    </b-form-input>
                </template>
                <template #cell(more)="data">
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {CUR_YEAR} from "@/modules/budget-request/components/js/budgetCurYear";
import store from "@/services/store";

export default {
    name: 'bip-values',
    props: {
        host: undefined
    },
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'name_ru',
                    label: 'Критерии'
                },
                {
                    key: 'unit_item',
                    label: 'Ед.изм'
                },
                {
                    key: 'value',
                    label: 'Значение'
                },
                {
                    key: 'weight',
                    label: 'Вес'
                },
                {
                    key: 'max',
                    label: 'Max',
                    variant: 'warning'
                },
                {
                    key: 'min',
                    label: 'Min'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],

            calcFlds: [
                'value',
                'weight',
                'max',
                'min'
            ],

            radioOptions: [
                { text: '-1', value: -1 },
                { text: '0', value: 0 },
                { text: '1', value: 1 }
            ],

            objectList: [],
            selObject: undefined,

            projectList: [],
            selProject: undefined,

            placeList: [
                {value: 1, text: 'город'},
                {value: 2, text: 'село'}
            ],
            selPlace: undefined,

            years: [
                {year: CUR_YEAR, name: `${CUR_YEAR}-${CUR_YEAR + 2}`},
                {year: CUR_YEAR + 1, name: `${CUR_YEAR + 1}-${CUR_YEAR + 3}`},
                {year: CUR_YEAR + 2, name: `${CUR_YEAR + 2}-${CUR_YEAR + 4}`}
            ],
            planPeriod: undefined,

            criteriaTypeList: [
                {id: '1', par_id: 0, name_ru: 'Бюджетные', child: []},
                {id: '2', par_id: 0, name_ru: 'Влияние на ЦИ', child: []},
                {id: '3', par_id: 0, name_ru: 'Потребность', child: []},
                {id: '4', par_id: 0, name_ru: 'Дополнительные', child: []}
            ],

            dictUnit: [],

            bipCifList: [],
            bipLinkCriterias: [],
            bipLinkTypes: [],

            valMap: new Map(),
            valTable: [],

            open: true,
            openFilterWindow: false,
            user: undefined,
            prevValues: 0
        };
    },

    created() {
        for (const y of this.years) {
            if (y.year === CUR_YEAR + 1) {
                this.planPeriod = y;
            }
        }

        this.$watch('planPeriod', () => {
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year);
            }
        });     this.$watch('selObject', () => {
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year);
            }
        });     this.$watch('selProject', () => {
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year);
            }
        });
        this.$watch('selPlace', () => {
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year);
            }
        });
    },

    async mounted() {
        this.user = store.state.user.sub;
        await this.loadObjectTypes();
        await this.loadProjectTypes();
        await this.loadDatas();
    },

    beforeUpdate() {
        for (const row of this.valTable) {
            if (parseInt(row.par_id) === 0) {
                row._rowVariant = 'info';
            } else {
                this.$set(row, 'editable', true);
            }
        }
    },

    computed: {
        showTable() {
            let result = [];
            if (this.selObject && this.selProject && this.selPlace && this.planPeriod) {
                result = this.valTable;
                if (this.selObject) {
                    result = result.filter(row => row.par_id === 0 || row.object_type === this.selObject.code);
                }

                if (this.selProject) {
                    result = result.filter(row => row.par_id === 0 || row.project_type === this.selProject.code);
                }

                if (this.selPlace) {
                    result = result.filter(row => row.par_id === 0 || row.place === this.selPlace.value);
                }

                if (this.planPeriod) {
                    result = result.filter(row => row.par_id === 0 || (row.begin_year <= this.planPeriod.year
                        && (row.end_date === null || (row.end_date !== null && row.end_year >= this.planPeriod.year))));
                }

                if (result.length > 0) {
                    for (const row of result) {
                        if (row.par_id > 0) {
                            this.$set(row, 'year', this.planPeriod.year);
                        }
                    }
                }
            }
            return result;
        }
    },

    methods: {
        async checkContent(code) {
            const response = await fetch('/api-py/get-links-by-criteria/' + code);
            const result = await response.json();
            return result;
        },

        checkDates() {
            for (const row of this.bipCifList) {
                if (row.edit) {
                    if (row.end_date !== null) {
                        const bd = new Date(row.begin_date);
                        const ed = new Date(row.end_date);
                        if (bd > ed) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },

        checkRequired() {
            for (const item of this.bipCifList) {
                if (item.edit) {
                    if (item.criteria_type !== null && item.criteria_type !== '4') {
                        if (item.code === null
                            || item.name_ru === null
                            || item.criteria_type === null
                            || item.unit === null
                            || item.data_type === null
                            || item.source_type === null
                            || item.source_type.toString().length === 0
                            || item.calc_type === null
                            || item.begin_date === null) {
                            return false;
                        }
                        if (item.source_type === 'W') {
                            if (item.operator === null) {
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        },

        createTable(elem, parentId, table) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);
            that.$set(item, 'par_id', parentId);
            that.$set(item, 'visible', true);

            that.$set(item, 'index', table.length);
            that.$set(table, table.length, item);

            if (item.par_id > 0) {
                that.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        that.$set(item, field, parseFloat(item[field]));
                    } else {
                        that.$set(item, field, 0);
                    }
                });
            } else {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);

                for (const ch of item.child) {
                    that.createTable(ch, item.id, table);
                }
            }

        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        async deleteItem(item, index) {
            let res = false;
            if (item.id > 0) {
                res = await this.checkContent(item.code);
            }
            this.$bvModal.msgBoxConfirm(
                'Подтвердите удаление: \"' + item.name_ru + '\" ',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (item.id <= 0) {
                            this.bipCifList.splice(this.bipCifList.findIndex(v => v.id === item.id), 1);
                            // this.loadDatas();
                            this.makeToast('success', 'Сообщение', 'Элемент удален');
                        } else {
                            if (res) {
                                this.$bvModal.msgBoxConfirm(
                                    'Критерий невозможно удалить, так как он используется в Связке. Исключите критерий из Связки и попробуйте удаление критерия снова',
                                    {
                                        title: 'Подтверждение',
                                        size: 'lg',
                                        buttonSize: 'sm',
                                        okVariant: 'danger',
                                        okTitle: 'Исправить',
                                        cancelTitle: 'Отмена',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true
                                    })
                                    .then(value => {
                                        return;
                                    })
                                    .catch(error => {
                                        this.makeToast('danger', 'Ошибка удаления', error.toString());
                                    });
                            } else {
                                this.delete(item, index);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        },

        async delete(item, index) {
            this.$set(item, 'table', 'bip_cif_list')
            const response = await fetch('/api-py/delete-item', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(item)
            });
            const result = await response.json();
            if (result.result === 'success') {
                await this.loadDict();
                this.makeToast('success', 'Сообщение', 'Элемент удален');
            }
        },

        doRowKey(keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(k, 3) + '_';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        async duplicate() {
            let answer = false;
            await this.$bvModal.msgBoxConfirm(
                'Вы действительно хотите скопировать данные с ' + (this.planPeriod.year - 1) + '-' + (this.planPeriod.year + 1)
                + ' периода. Если уже введены данные за ' + this.planPeriod.name + ', они будут удалены',
                {
                    title: 'Подтверждение',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    cancelVariant: 'light',
                    okTitle: 'Дублировать',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    answer = value;
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка обновления', error.toString());
                });
            if (answer) {
                this.prevValues = await this.getCriteriaValues(this.selObject.code,
                    this.selProject.code,
                    this.selPlace.value,
                    this.planPeriod.year - 1);

                if (this.prevValues === 0) {
                    this.makeToast('warning', 'Внимание', 'Данные за предыдущий период не найдены!');
                } else {
                    for (const row of this.showTable) {
                        if (row.par_id > 0) {
                            this.calcFlds.forEach((field) => {
                                this.$set(row, field, 0);
                                this.$set(row, 'edit', true);
                            });
                            this.valMap.set(this.getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']), row);

                            const value = this.valMap.get(this.doRowKey(
                                [this.planPeriod.year - 1, row.object_type, row.project_type, row.place, row.criteria]));
                            if (value) {
                                this.calcFlds.forEach((field) => {
                                    this.$set(row, field, value[field]);
                                });
                            }
                        }
                    }
                }
            }
        },

        async getCriteriaValues(object_type, project_type, place, year) {
            try {
                let response = null;
                response = await fetch('/api-py/get-criteria-values-by-params/'
                    + object_type + '/' + project_type + '/' + place + '/' + year);
                response = await response.json();
                this.prevValues = await response.length;
                for (const row of response) {
                    await this.valMap.set(this.getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']), row);
                }

                setTimeout(() => {
                    if (this.planPeriod.year === year) {
                        for (const row of this.showTable) {
                            if (row.par_id > 0) {
                                const value = this.valMap.get(this.getRowKey(row, ['year', 'object_type', 'project_type', 'place', 'criteria']));
                                if (value) {
                                    this.calcFlds.forEach((field) => {
                                        this.$set(row, field, value[field]);
                                    });
                                }
                            }
                        }
                    }
                }, 500);
                return this.prevValues;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getCriteriaValues', error.toString());
            }
        },

        getItem (code, list, field) {
            if (code !== null) {
                for (const item of list) {
                    if (item.hasOwnProperty(field)) {
                        if (item[field] !== null && item[field].toString() === code.toString()) {
                            return item;
                        }
                    }
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getNumber(value, digit = 1) {
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (value === null) {
                return 0;
            }
            if (typeof (value) === 'string') {
                return (value ? parseFloat(parseFloat(value).toFixed(digit)) : 0);
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(digit));
            }
            if (typeof (value) === 'boolean') {
                return (value ? 1 : 0);
            }
            return 0;
        }, // возращает число

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '_';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        inputFixed(item, field, value, digit) {
            this.$set(item, field, this.number(value).toFixed(digit));

            this.$set(item, 'edit', true);
            this.valMap.set(this.getRowKey(item, ['year', 'object_type', 'project_type', 'place', 'criteria']), item);
        }, // форматирует введенное значение до digit цифр после запятой

        isStr(value) {
            return ((value === null) || (value === 'None') ? '' : value);
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        async loadBipCifList() {
            try {
                const response = await fetch('/api-py/dictionary/bip_cif_list');
                this.bipCifList = await response.json();
                this.bipCifList.sort(this.sortByField('code'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBipCifList', error.toString());
            }
        },

        async loadBipLinkCriterias() {
            try {
                const response = await fetch('/api-py/dictionary/bip_link_criterias');
                this.bipLinkCriterias = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBipLinkCriterias', error.toString());
            }
        },

        async loadBipLinkTypes() {
            try {
                const response = await fetch('/api-py/dictionary/bip_link_types');
                this.bipLinkTypes = await response.json();
                this.bipLinkTypes.sort(this.sortByField('id'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadBipLinkTypes', error.toString());
            }
        },

        async loadDatas() {
            await this.loadUnits();
            await this.loadBipCifList();
            await this.loadBipLinkTypes();
            await this.loadBipLinkCriterias();
            // await this.loadBipCriteriaValues();
            for (const blc of this.bipLinkCriterias) {

                const cif = this.bipCifList.filter(row => row.code === blc.criteria);
                if (cif.length > 0) {
                    for (const [key, value] of Object.entries(cif[0])) {
                        if (!['id', 'begin_date', 'end_date'].includes(key)) {
                            this.$set(blc, key, value);
                        }
                    }
                    this.$set(blc, 'unit_item', this.getItem(blc.unit, this.dictUnit, 'national_symbol'));
                }

                const blt = this.bipLinkTypes.filter(row => row.id === blc.link);
                if (blt.length > 0) {
                    for (const [key, value] of Object.entries(blt[0])) {
                        if (key !== 'id') {
                            this.$set(blc, key, value);
                        }
                    }

                    this.$set(blc, 'begin_year', new Date(blt[0].begin_date).getFullYear());
                    if (blt[0].end_date !== null) {
                        this.$set(blc, 'end_year', new Date(blt[0].end_date).getFullYear());
                    } else {
                        this.$set(blc, 'end_year', null);
                    }
                }

                for (const gr of this.criteriaTypeList) {
                    if (gr.id === blc.criteria_type) {
                        this.$set(blc, 'par_id', gr.id);
                        gr.child.push(blc);
                    }
                }
            }
            for (const gr of this.criteriaTypeList) {
                this.createTable(gr, 0, this.valTable);
            }
            console.log('valtable', this.valTable);
        },

        async loadUnits() {
            try {
                const response = await fetch('/api-py/dictionary/unit');
                this.dictUnit = await response.json();
                this.dictUnit.sort(this.sortByField('code'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadUnits', error.toString());
            }
        },

        async loadObjectTypes() {
            try {
                const response = await fetch('/api-py/dictionary/bip_project_object_list');
                this.objectList = await response.json();
                this.objectList.sort(this.sortByField('name_ru'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadObjectTypes', error.toString());
            }
        },

        async loadProjectTypes() {
            try {
                const response = await fetch('/api-py/dictionary/bip_project_type_list');
                this.projectList = await response.json();
                this.projectList.sort(this.sortByField('name_ru'));
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadProjectTypes', error.toString());
            }
        },

        makeToast(variant = null, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },

        message(sms) {
            this.$bvModal.msgBoxConfirm(
                sms, {
                    title: 'Внимание!',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Исправить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    modalClass: 'del-item-modal-script'
                })
                .then(value => {
                    return;
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка проверки контроля', error.toString());
                });
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        openAll() {
            this.open = !this.open;

            for (const row of this.valTable) {
                if (row.par_id > 0) {
                    row.visible = this.open;
                } else {
                    this.openChilds(row, this.open);
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool !== undefined ? bool : !parent.open);

            for (const row of this.valTable) {
                if (parent.id === row.par_id) {
                    row.visible = parent.open;
                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        resetFilter() {
            this.selObject = null;
            this.selProject = null;
            this.selPlace = null;
            this.planPeriod = null;
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save() {
            const values = [];
            for (const row of this.valMap.values()) {
                if (row.edit) {
                    this.$set(row, 'user_name', this.user);
                    values.push(row);
                }
            }
            console.log('valMap', values);
            const response = await fetch('/api-py/save-bip-criteria-values', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            const result = await response.json();
            if (result.result === 'success') {
                this.makeToast('success', 'Сообщение', 'Элемент сохранен')
            }
        },

        setCurCriteria(item) {
            this.$set(item, 'edit', true);
            if (item.source_type !== 'W') {
                item.operator = null;
            }
            if (item.criteria_type === '4') {
                item.calc_type = null;
            }
            this.$set(item, 'unit', item.unit_item !== null ? item.unit_item.national_symbol : null);
        },

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }
    }
};
</script>

<style>
.is-hidden {
    display: none !important;
}
</style>
<style scoped>
.item-tool {
    margin-top: 5px;
}
</style>
